export default {
    cn: {
        content: {
            icp: {
                company: '© 2021 上海丽昂数字科技有限公司',
                url: 'http://beian.miit.gov.cn',
                link: '沪 ICP 备 19003696 号-1',
                url2: 'http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31010402009473',
                link2: '沪公网安备 31010402009473号',
            },
            scroll: "往上滑",
            fname: "FIRST NAME",
            lname: "LAST NAME",
            content: "「DANDELION」是连接世界的艺术作品\n飘舞的蒲公英绒毛带着大家的和平祈愿，\n飞越国界，让世界开满绚烂。",
            content2: "用你的名字\n做成世界上唯一的蒲公英吧",
            content3: "只属于你的蒲公英完成啦",
            content4: "将此页面对准扫码器",
            sendPrayer: "传递自己的祈愿",
            watege1: "你的祈愿蒲公英，从",
            watege2: " 传递到了 ",
            watege3: "开满了花朵。",
            watege4: "你的祈愿蒲公英，传递到了",
            watege5: "开满了花朵",
            webJoin: "在网上飘散蒲公英",
            webJoinAR: "綿毛を飛ばす",
            tipsAR: "枠の中に、ワインラベルの綿毛を合わせて下さい。",
            nextAR: "NEXT",
            nameWraning: "\n请输入十个以内的半角英文",
            ngWraning: '\n含有不能使用的信息。\n请更正您所输入的信息并重试。\n',
            loginTitle1: "欢迎关注我们",
            loginTitle2: "关注后请返回这个界面",
            loginBtn: "开始体验",
            btnClose: "ok",

            siteInfo: {
                "s00100": {
                    id: "s00100",
                    name: "",
                    qrDisplay: false,
                    accountDisplay: true,
                    img: "./res/texture/waitBg/watage_bg_basic.jpg",
                    logo: "./res/texture/waitBg/watage_logo_basic.png"
                },
                "s00101": {
                    id: "s00101",
                    name: "佐賀",
                    qrDisplay: true,
                    accountDisplay: false,
                    img: "./res/texture/waitBg/watage_bg_saga.jpg",
                    logo: "./res/texture/waitBg/watage_logo_basic.png"
                },
                "s00102": {
                    id: "s00102",
                    name: "佐賀",
                    qrDisplay: false,
                    accountDisplay: false,
                    img: "./res/texture/waitBg/watage_bg_saga.jpg",
                    logo: "./res/texture/waitBg/watage_logo_basic.png"
                },
                "s00103": {
                    id: "s00103",
                    name: "日本沈没",
                    qrDisplay: true,
                    accountDisplay: false,
                    img: "./res/texture/waitBg/watage_bg_nihonchinbotsu.jpg",
                    logo: "./res/texture/waitBg/watage_logo_nihonchinbotsu.png"
                },
                "s00104": {
                    id: "s00104",
                    name: "東京タワー",
                    qrDisplay: true,
                    accountDisplay: true,
                    img: "./res/texture/waitBg/watage_bg_tokyotower.jpg",
                    logo: "./res/texture/waitBg/watage_logo_basic.png"
                },
                "s00105": {
                    id: "s00105",
                    name: "彦根城",
                    qrDisplay: true,
                    accountDisplay: true,
                    img: "./res/texture/waitBg/watage_bg_basic.jpg",
                    logo: "./res/texture/waitBg/watage_logo_basic.png"
                },
                "s00106": {
                    id: "s00106",
                    name: "首里城",
                    qrDisplay: true,
                    accountDisplay: true,
                    img: "./res/texture/waitBg/watage_bg_basic.jpg",
                    logo: "./res/texture/waitBg/watage_logo_basic.png"
                },
                "s00107": {
                    id: "s00107",
                    name: "京都",
                    qrDisplay: true,
                    accountDisplay: false,
                    img: "./res/texture/waitBg/watage_bg_basic.jpg",
                    logo: "./res/texture/waitBg/watage_logo_basic.png"
                },
                "s00108": {
                    id: "s00108",
                    name: "シンガポール",
                    qrDisplay: false,
                    accountDisplay: true,
                    img: "./res/texture/waitBg/watage_bg_basic.jpg",
                    logo: "./res/texture/waitBg/watage_logo_basic.png"
                },
                "s00109": {
                    id: "s00109",
                    name: "高知城",
                    qrDisplay: true,
                    accountDisplay: true,
                    img: "./res/texture/waitBg/watage_bg_kouchijo.jpg",
                    logo: ""
                },
                "s00110": {
                    id: "s00110",
                    name: "",
                    qrDisplay: true,
                    accountDisplay: true,
                    img: "./res/texture/waitBg/watage_bg_kumatojo.jpg",
                    logo: ""
                },
                "s00111": {
                    id: "s00111",
                    name: "",
                    qrDisplay: true,
                    accountDisplay: true,
                    img: "./res/texture/waitBg/watage_bg_paris.png",
                    logo: ""
                },
                "s00112": {
                    id: "s00112",
                    name: "",
                    qrDisplay: true,
                    accountDisplay: true,
                    img: "./res/texture/waitBg/watage_bg_webar.jpg",
                    logo: ""
                },
                "s00113": {
                    id: "s00113",
                    name: "",
                    qrDisplay: true,
                    accountDisplay: true,
                    img: "./res/texture/waitBg/watage_bg_basic.jpg",
                    logo: "./res/texture/waitBg/watage_logo_basic.png"
                },
                "s00114": {
                    id: "s00114",
                    name: "",
                    qrDisplay: true,
                    accountDisplay: true,
                    img: "./res/texture/waitBg/watage_bg_basic.jpg",
                    logo: "./res/texture/waitBg/watage_logo_basic.png"
                },
                "s00115": {
                    id: "s00115",
                    name: "",
                    qrDisplay: true,
                    accountDisplay: true,
                    img: "./res/texture/waitBg/watage_bg_basic.jpg",
                    logo: "./res/texture/waitBg/watage_logo_basic.png"
                },
                "s00116": {
                    id: "s00116",
                    name: "",
                    qrDisplay: true,
                    accountDisplay: true,
                    img: "./res/texture/waitBg/watage_bg_basic.jpg",
                    logo: "./res/texture/waitBg/watage_logo_basic.png"
                },
                "s00117": {
                    id: "s00117",
                    name: "",
                    qrDisplay: true,
                    accountDisplay: true,
                    img: "./res/texture/waitBg/watage_bg_basic.jpg",
                    logo: "./res/texture/waitBg/watage_logo_basic.png"
                },
                "s00118": {
                    id: "s00118",
                    name: "",
                    qrDisplay: true,
                    accountDisplay: true,
                    img: "./res/texture/waitBg/watage_bg_basic.jpg",
                    logo: "./res/texture/waitBg/watage_logo_basic.png"
                },
                "s00119": {
                    id: "s00119",
                    name: "",
                    qrDisplay: true,
                    accountDisplay: true,
                    img: "./res/texture/waitBg/watage_bg_basic.jpg",
                    logo: "./res/texture/waitBg/watage_logo_basic.png"
                },
                "s00120": {
                    id: "s00120",
                    name: "",
                    qrDisplay: true,
                    accountDisplay: true,
                    img: "./res/texture/waitBg/watage_bg_basic.jpg",
                    logo: "./res/texture/waitBg/watage_logo_basic.png"
                },
                "s00121": {
                    id: "s00121",
                    name: "",
                    qrDisplay: true,
                    accountDisplay: true,
                    img: "./res/texture/waitBg/watage_bg_basic.jpg",
                    logo: "./res/texture/waitBg/watage_logo_basic.png"
                },
            }
        }


    }
}