import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import SysDef from "../SysDef";
import Qs from 'qs'
Vue.use(VueAxios, axios)
export default ({

    HttpGetRequest: function(url, callback, data = null) {
        Vue.axios.get(url, { params: data }).then((response) => {

            if (response.status == 200) {
                if (callback)
                    callback(response.data);

            }

        }).catch((error) => {
            console.log(error);

            if (callback)
                callback(null);
        })
    },
    HttpPostRequst: function(url, callback, data) {

        var qdata = Qs.stringify(data);
        Vue.axios.post(url, qdata, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }).then((response) => {
            if (response.status == 200) {

                if (callback)
                    callback(response.data);

            }
        }).catch((error) => {
            console.log(error);

            if (callback)
                callback(null);
        })
    },
    RequestSubmitName: function(_fName, _lName, _watageShape, _watageColor, _lg, callBack = null) {
        var url = SysDef.apiUrl + 'submitName';
        var obj = new Object();
        obj.firstName = _fName;
        obj.lastName = _lName;
        obj.watageShape = _watageShape;
        obj.watageColor = _watageColor;
        obj.language = _lg;
        this.HttpPostRequst(url, callBack, obj);
    },
    RequestPray: function(_intId, _siteId, callBack = null) {
        var url = SysDef.apiUrl + 'pray';
        var obj = new Object();
        obj.intId = _intId;
        obj.siteId = _siteId;
        this.HttpPostRequst(url, callBack, obj);
    },
    RequestGetPicture: function(_intId, callBack = null) {
        var url = SysDef.apiUrl + "getPicture";
        var obj = new Object();
        obj.intId = _intId;
        this.HttpGetRequest(url, callBack, obj);
    },
    RequestSiteInfo(_siteId, callBack = null) {
        var url = SysDef.apiUrl + "siteInfo";
        var obj = new Object();
        obj.siteId = _siteId;
        this.HttpGetRequest(url, callBack, obj);
    },

    jsonp(url, data, fn) {
        if (!url)
            throw new Error('url is necessary')
        const callback = 'CALLBACK' + Math.random().toString().substr(9, 18)
            // const callback = 'cb_callback'
        const JSONP = document.createElement('script')
        JSONP.setAttribute('type', 'text/javascript')

        const headEle = document.getElementsByTagName('head')[0]

        let ret = '';
        if (data) {
            if (typeof data === 'string')
                ret = '&' + data;
            else if (typeof data === 'object') {
                for (let key in data)
                    ret += '&' + key + '=' + encodeURIComponent(data[key]);
            }
            ret += '&_time=' + Date.now();
        }
        JSONP.src = `${url}?callback=${callback}${ret}`;

        window[callback] = function(r) {
            fn && fn(r)
            headEle.removeChild(JSONP)
            delete window[callback]
        }

        headEle.appendChild(JSONP)
    }


})