import Vue from 'vue'
import App from './App.vue'
// import Vconsole from 'vconsole'
import VueI18n from 'vue-i18n'
import { localization } from './lang/index.js'
import VideoPlayer from 'vue-video-player'
import 'vue-video-player/src/custom-theme.css'
import 'video.js/dist/video-js.css'
Vue.use(VideoPlayer)
Vue.config.productionTip = false;
// new Vconsole();
Vue.use(VueI18n);
const i18n = new VueI18n({
    locale: 'jp',
    fallbackLocale: 'jp',
    messages: localization
})
new Vue({
    i18n: i18n,
    render: h => h(App),
}).$mount('#app')