<template>
  <div id="main" :class="currentFont" @touchstart.capture="touchStart" @touchmove.capture="touchMove"  @touchend.capture="touchEnd"  @mousedown="mouseDown" @mousewheel="mouseWheel" style="overflow-x:hidden">
           <div class="width-normal pos-relative margin-top-10" v-if="main_page_step<4&& main_page_step>=0">
                <img style="width:100%" :src="logo_url" alt="" v-if="main_page_step<4&& main_page_step>=0" >
                  <div class="top-right">
                       <div class="lg-div">
                          <div class="zq-drop-list-new" >
                              <span @click="showLg=true">{{lgs[lgIndex].name}}</span>
                              <div class="triangle-down"></div>
                              <ul id='lgTarget' v-show="showLg">
                                <li :class="[{'chossLi':index==lgIndex}]" v-for="(item, index) in lgs" :key="index" @click="onClickLg(index)">{{item.name}}</li>
                              </ul> 
                          </div>
                       </div>
                    
                  </div>
                
            </div>
            <div  v-if="main_page_step == -1" class="fade-in-ani full-width">
                            <video-player class="video-player vjs-custom-skin"
              ref="videoPlayer"
              :playsinline="true"
              :options="playerOptions" @ended="onloadGif">
      </video-player>
            </div>
            <div v-if="main_page_step == 0" class="fade-in-ani">

                 <br>
                 <br>
                 <br>
                 <div v-if="isCN" style="height:150px">
                 </div>
                 <div v-else>
                  <p class="width-full main-word  font-size-18" :class="currentBoldFont">{{$t('content.loginTitle1')}}</p>  
                  <br>
                 <div class="icon-div-login" style="width:50%">
                   <a @click="logEvent('loginMenu_line')" href="https://lin.ee/dhqsNTV"><img style="width:90%" :src="lgIndex==0?'https://scdn.line-apps.com/n/line_add_friends/btn/ja.png':'https://scdn.line-apps.com/n/line_add_friends/btn/en.png'" alt="友だち追加" border="0"></a>
     
                    <a @click="logEvent('loginView_facebook')" href="https://www.facebook.com/NAKEDINC.official/" target="_blank" >
                      <img :src="lgIndex==0?icon_7_url:icon_7_url_en" alt="">
                    </a>
                    <a @click="logEvent('loginView_ins')" href="https://www.instagram.com/naked_inc/?hl=ja" target="_blank" >
                      <img :src="lgIndex==0?icon_8_url:icon_8_url_en" alt="">
                    </a>
                </div>

                 <br>
                 <br>  
                 <p class="width-normal main-word font-size-18" :class="currentBoldFont">{{$t('content.loginTitle2')}}</p>  
              
                <img src="res/texture/dp_yajirushi.png" alt=" " >   
                 </div>
                 
                <div class="input-frame input-next input-btn" style="width:70% " @click="login">
                      <img src="res/texture/dp_next.png" alt=" " >   
                      <span class="pos-absolute " :class="currentBoldFont" style="width:100%;font-size:5vw;top:45%;left:50%" > {{$t('content.loginBtn')}}</span>
                </div>

                 <div v-if="isCN" style="height:200px">
                 </div>
            </div>
    <div id="step-div-0" v-show="main_page_step == 1"  class="fade-in-ani">
 
          <div id="step-div-0-1">
          <br> 
          <br> 
            <img :src="lgIndex==2?title_url_fr:lgIndex==3?title_url_cn:title_url"  :class="[{'show-opacity':isShowTitle},{'hide-opacity':isShowHideContent}]" style="width:80%;opacity:0">
            <br>   
          <br> 
          <p  class="width-normal main-word font-size-13 " style="opacity:0;line-height:7vw" :class="[{'font-float':isShowContent},{'hide-opacity':isShowHideContent}]" v-html="$t('content.content')"></p>   
            <div class="pos-relative scroll" v-if="!isShowHideContent">
                  <span class="font-size-18" :class="currentBoldFont" style="white-space:nowrap" >{{$t('content.scroll')}}</span> 
                <img class="line" :src="line_url" alt="" >
                  
              </div>
        </div>
        <br>
          
        <div style="height:400px"></div>

        <div  id="MainRender" class="share-view earth_view" :class="[{'show-opacity':isShowModel},{'moveToCenter':isShowHideContent}]" style="opacity:0">
        </div>
    </div>
    <div v-show="main_page_step == 2" class="fade-in-ani" >

          <br>
          <br>
        <p  class="width-normal main-word font-size-14" :class="currentBoldFont"   style="line-height:7vw;letter-spacing:0.5vw" >{{$t('content.content2')}}</p>
        <br>
        <div class="pos-relative">
            <img class="width-normal"  style="width:60%" :src="watage_url" alt="">
              <div class="pos-inputs" >
                <div class="input-div">
                  <span class="font-Bold-en font-size-18 input-name">{{$t('content.fname')}}</span>
                    <div class="input-frame pos-relative">
                        <img :src="btn_url" alt="">     

                        <img src="res/texture/dp_redwaku.png" alt="" class="wrong" v-show="wrongF">    
                        <input class="form-input font-Bold-en" v-model="usernameF" type="text" placeholder="" maxlength="10" minlength="1" @blur="checkNameType" />
              
                    </div>
              
                  </div>
                  <div class="input-div">
                      <span class="font-Bold-en font-size-18 input-name">{{$t('content.lname')}}</span>
                      <div class="input-frame pos-relative">
                        <img :src="btn_url" alt="">   
                        <img src="res/texture/dp_redwaku.png" alt="" class="wrong" v-show="wrongL">   
                        <input class="form-input font-Bold-en" v-model="usernameL" type="text" placeholder="" maxlength="10" minlength="1" @blur="checkNameType" />
              
                      </div>
                  
                  </div>
              </div>
          </div>
          <div class="input-frame input-next input-btn" @click="sendNameToServer">
              <img :src="btn_url" alt=" " >   
              <span class="font-Bold-en"  style="top:42%;left:48%;width:100%;"  > OK</span>
          </div>
       
    </div>
    <div class="fade-in-ani" v-show="main_page_step == 3" >
        <br>
        <p class="font-Bold-Italic-en font-size-24" style="width:70% ;margin:auto;">{{cur_date}}</p>
     
        <br>
                 <p class="width-normal main-word font-size-15" :class="currentBoldFont">{{$t('content.content3')}}</p>  
        <div id="watage_node" class="pos-relative">

            <div  style="width:60%; height:80vw;"></div>
            <div id="watage_img_user"  class="pos-absolute" style="font-size:80%">
                <img style="width:90%" :src="my_watage_url" alt="" @load="loadWatage" >
                <div class="pos-absolute" style="top:59%;left:50% width:100%;font-size:100%" >
                    <p class="font-deca" >{{usernameF}}</p>
                    <p class="font-deca" >{{usernameL}}</p>
                </div>
              </div>
              
      
        </div>
            <div v-if="curSiteRemoteInfo.qrDisplay" @click="showQR">
              
              <br>
                 <p class="width-normal main-word font-size-15" :class="currentBoldFont">{{$t('content.content4')}}</p>  
              <br>
              <vue-qr class="div-QR"  style="width:50%"  :text="qrString"  colorDark="#DB1590" colorLight="#fff" ></vue-qr>
              <br>
               
          </div>
              <!-- <span class="font-Bold-en font-size-18" >scroll</span> -->
              <br>
              <br>
               <div v-if="isCN" class="input-frame input-next input-btn" @click="showWebQRJoin">
                            <img :src="btn_url" alt=" " >   
                            <span class="font-Bold-en"  style="top:42%;left:48%;width:100%;"  > NEXT</span>
            </div>
              <div class="pos-relative">
                <!-- <img class="line" :src="line_url" alt=""> -->
                <div class="div-web font-size-18 input-btn" :class="currentBoldFont"  @click="showWebJoinAR" v-if="isWebARType">{{$t('content.webJoinAR')}}</div>
                <div class="div-web font-size-18 input-btn" :class="currentBoldFont"  @click="showWebJoin" v-else>{{$t('content.webJoin')}}</div>

              </div>
              <br>
               
      </div>
  <div class="pop" v-if="isShowQR" @click="closeQR" >
              <div class="share-view" >

              <vue-qr class="div-QR" :text="qrString"  colorDark="#DB1590" colorLight="#fff" ></vue-qr>
                  </div>
   </div>
   <div class="pop pop2" v-if="isShowDialog" @click="closeDialog" >
                  <div class="dialog-view" >
                    <span :class="lgIndex==0?'dialog-view-span-jp':'dialog-view-span-en'"> {{dialogTips}}</span>
                     
                      <span class="btn-close">{{$t('content.btnClose')}}</span>
                  </div>
   </div>
      <div v-if="main_page_step == 4 && !isWebARType" class="fade-in-ani pos-relative width-full" style="overflow:hideen">

        <img style="width:100%" :src="bless_earth_url" alt="">
        <img id="bless_watage" style="width:120%" class="pos-absolute" :src="bless_watege_url" alt="" v-show="showGif" @load="oncanPlay">
           <div class="pos-absolute input-btn " style="margin:auto;width:80% ;top:90%"  @click="sendPrayerFunc"  >
              <img style="width:100% ;height:19vw" :src="btn_url" alt=" ">   
              <span class="font-Regular-jp font-size-18 pos-absolute " style="top:43%;width:80%;" > {{$t('content.sendPrayer')}}</span>
          </div>
      </div>
      <div v-if="isWebARType" class="fade-in-ani pos-relative width-full" >
        <div v-show="main_page_step == 4 " class="fade-in-ani pos-relative width-full" style="overflow:hideen" :style="{width:winWidth+'px',height:winHeight+'px'}" >
        <div id="ARVideo" class="ar-gif  pos-relative" :style="{width:winWidth+'px',height:winHeight+'px',overflow:'hideen'} ">
         
        </div> 
         <div id="ARRender" class="ar-gif  pos-relative" :style="{width:winWidth+'px',height:winHeight+'px',overflow:'hideen'} ">
         
        </div>
          <div  class="ar-gif  pos-relative" :style="{width:winWidth+'px',height:winHeight+'px',overflow:'hideen'} ">
          <img class="ar-gif"  style="z-index:3; width:40%;top:40%" src="res/texture/dp_ar_vinpassion_marker_guide.png" alt=""  v-if="isShowARFrame">
          <img class="ar-gif" style="z-index:3; width:60%;top:40%" src="res/texture/dp_ar_vinpassion_waku.png" alt="" v-if="isShowARFrame">
          <p class="font-Regular-jp font-size-18 pos-absolute " style="top:75%;width:80%;z-index:3; "  v-if="isShowARFrame"> {{$t('content.tipsAR')}}</p>
           <div class="pos-absolute input-btn " style="margin:auto;width:60% ;top:85%;z-index:3; "  @click="sendPrayerFuncAR" v-if="isShowARBtn" >
              <img style="width:100% ;height:19vw" :src="btn_url" alt=" ">   
              <span class="font-Regular-jp font-size-18 pos-absolute " style="top:43%;width:80%;" > {{$t('content.nextAR')}}</span>
          </div>
        </div>
       
      </div>
      </div>
    
      <div v-if="main_page_step == 5"  class="pos-relative  width-full" >
          <img  class="width-full fade-in-ani-delay" :src="curSite.img" alt="">
          <img  class=" fade-in-ani-delay waitLogo" :src="curSite.logo" alt="" v-if="curSite.logo">
          <span class="font-size-14 fade-in-ani pos-absolute"  :class="currentBoldFont"  style="top:10%;left:50%;width:80%;white-space:pre-wrap;">  {{waitWords}}</span>
      </div>

      <div v-show="main_page_step == 6" class="fade-in-ani pos-relative width-full" >
          <div  class="width-full pos-relative">
            <div ref="downloadImg" class="width-full pos-relative">
                     <img id='downlodBg' class="width-full" :src="pictureUrl" alt="" crossorigin="anonymous" @load="resetImg">
                     <img class="width-full down-flower" src="res/texture/flowers.png" alt="" v-if="isBlackBg">
                     <img class="width-full down-flower" src="res/texture/flowers_b.png" alt="" v-if="!isBlackBg">
                      <div class="final-logo pos-relative">
                          <img class="width-full"  src="res/texture/dp_logo_w.png" alt="" v-if="isBlackBg">
                          <img class="width-full"  src="res/texture/dp_logo_b.png" alt="" v-if="!isBlackBg">
                          <span  class="final-time " :class="[isBlackBg?'w-font':'b-font',currentBoldFont]">{{prayTime}}</span>
                          <span  class="final-name" :class="[isBlackBg?'w-font':'b-font',currentBoldFont]" >{{prayWord}}</span>
                      </div>
                  <img style="position:absolute;top:65%;left:35%;width:80%;transform:translate(-50%,-50%) rotate(25deg)" :src="newImgData" alt="" >
            </div>
          
          <div class="width-full pos-absolute input-btn" style="top:90%;left:90%" @click="downloadImg" v-if="!isCN">

              <img style="width:10%" :src="download_url" alt=" " v-if="isBlackBg">   
              <img style="width:10%" src="res/texture/icon_download_b.png" alt=" " v-if="!isBlackBg">   
          </div>
          
          </div>

         
          <br>
          <div > 
               <div class="icon-div" style="width:70%;justify-content: center;" v-if="isCN">
                <a @click="logEvent('endView_wechat')" href="https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=MzUzMDczMTIyMA==&scene=124#wechat_redirect" target="_blank" >
                  <img :src="icon_9_url" alt="">
                </a>
              </div>
              <div class="icon-div" style="width:70%" v-else>
                <a @click="logEvent('endView_facebook')" href="https://www.facebook.com/NAKEDINC.official/" target="_blank" >
                  <img :src="icon_3_url" alt="">
                </a>
                <a @click="logEvent('endView_ins')" href="https://www.instagram.com/naked_inc/" target="_blank" >
                  <img :src="icon_4_url" alt="">
                </a>

                <a @click="logEvent('endView_line')" href="https://lin.ee/dhqsNTV" target="_blank" >
                  <img :src="icon_5_url" alt="">
                </a>
              </div>
                <br><br>
                <a @click="logEvent('endView_homepage')" href="https://breathbless.com/" target="_blank" v-if="!isCN">
                  <img :src="icon_1_url" alt="">
                </a>
                <br><br>
                 <img style="width:70%" :src="logo_url" alt="" @click="backToMain">
                <br><br>
                <br><br>
          </div>
      </div>
      <div v-if="isCN" >
        <span  style="font-size:0.825rem">{{$t('content.icp.company')}}</span>
        <br>
         <a  style="font-size:0.825rem" class="icp-a" :href="$t('content.icp.url')" target="_blank" >
         {{$t('content.icp.link')}}
                </a>
        <br>

         <a  style="font-size:0.825rem;position:relative" class="icp-a" :href="$t('content.icp.url2')" target="_blank" >
           <img src="res/texture/gongan.png" alt="" style="position:absolute;left:-25px">
                  <span >{{$t('content.icp.link2')}}</span>
                </a>
        <br>
        <br>
      </div>
          <div class="pop " style="background:#89898cd9" v-if="isLoading || isConnecting" >
                  <div class="loading-view" >
                    <img src="res/texture/loading.gif" alt="">
                  </div>
          </div>
  </div>
</template>

<script>
    import * as THREE from 'three'
    import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader'
  import html2canvas from "html2canvas";
  import Vue from 'vue';
  import vueQr from "vue-qr";
  import netMgr from "../network/netMgr.js"
  import { initializeApp } from 'firebase/app';
  import { getAnalytics, logEvent } from "firebase/analytics";
 const firebaseConfig = {
  apiKey: "AIzaSyD8wrrVysaMuzXZWqvvfgzsB76oVxHOzyo",
  authDomain: "breath-bless02.firebaseapp.com",
  projectId: "breath-bless02",
  storageBucket: "breath-bless02.appspot.com",
  messagingSenderId: "1048067691296",
  appId: "1:1048067691296:web:b444f3500c16c305547d45",
  measurementId: "G-D14G43BBXK"
};
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);
  const webAR = new WebAR(1000, 'https://cn1-crs.easyar.com:8443/search', 'K5R8Jvayo4V/9m3LBvoT7ZavEySnuCxXYs4myQmsPE1e/d4UXZZex3GV3veqSqmiX9lnAqOceQYoFDPBXj9bQQ==');

  Vue.use(vueQr);
  // var vc = new VConsole();
  var Watege = function(color){
            this.init(color);
  };
  Watege.prototype = {
              init : function(color){
                  this._top = 40+20*Math.random();
                  this._left = 40+20*Math.random();
                  this._scale = 10+30*Math.random();
                  this._rotate = 180*Math.random();
                  this._move_h_speed = 5+ Math.random();
                  this._move_v_speed = Math.random()>0.5?(0.5+Math.random()):-(0.5+Math.random());
                  this._scale_speed = Math.random();
                  this._rotate_speed =Math.random()>0.5?Math.random():-Math.random();
                  this._opacity = 0; 
                   this.img = document.getElementById('watage_img_user').cloneNode(true);
                   this.img.src = this.url;
                   this.img.style.position = "fixed";

                   this.img.style.filter = "hue-rotate("+color+"deg)"
                   this.img.style.opacity= 0;
                   this.img.style.fontSize = "1%";
                    this.img.style.top = this._top+"%";
                    this.img.style.left = this._left+"%";
                   this.img.style.width = this._scale+"%";
              
                   this.img.style.transform = "translate(-50%,-50%) rotate("+this._rotate+"deg)";
                   document.getElementById('main').appendChild( this.img );
                   var self = this;
                  requestAnimationFrame(function fn(){
                   
                      self._top -= self._move_h_speed/20 ;
                      self._left += self._move_v_speed/5;
                      self._rotate += self._rotate_speed;
                      self._opacity += 0.1;
                      self.img.style.opacity = self._opacity;
                      self.img.style.top = self._top+"%";
                      self.img.style.left = self._left+"%";
                      self.img.style.width = self._scale+"%";
                      self.img.style.transform = "translate(-50%,-50%) rotate("+self._rotate+"deg)";
                      requestAnimationFrame(fn);
                  });
              },
              destroyFunc:function(){
                if(document.getElementById('main'))
                 document.getElementById('main').removeChild( this.img);
              },
              render : function(){
                
              },
};
 var FloatWatege = function(index,color){
            this.init(index,color);
  };
  FloatWatege.prototype = {
              init : function(index,color){

                var posx = 20;

                var posy = 50;
                switch(index)
                {
                  case 1:
                    posx = 70;
                    posy = 60;
                    break;
                  case 2:
                    posx = 25;
                    posy = 80;
                    break;  
                  case 3:
                    posx = 70;
                    posy = 90;
                    break;   
                  case 4:
                    posx = 80;
                    posy = 40;
                    break;   
                  case 5:
                    posx = 90;
                    posy = 70;
                    break;
                }
                  this._top=this.top = posy;
                  this._left=this.left = posx;
                  this._width=this.width = 30 + +30*Math.random();
                  this._rotate =this.rotate  = Math.random()>0.6?45:Math.random()>0.3?0:-45;
                  this.img = document.getElementById('watage_img_user').cloneNode(true);
                   document.getElementById('main').appendChild( this.img );
                  this.img.style.left = this.left+"%";
                          this.img.style.width = this.width+"%";
                   this.img.style.position = "fixed";
                   this.img.style.fontSize = "10%";
                   this.img.style.filter = "hue-rotate("+color+"deg)"
                  var range1 = -(0.06 + Math.random() * 0.03);
                  var range2 = (0.06 + Math.random() * 0.03);
                  var range3 = -0.1* Math.random();
                   var self = this;
                  var renderFunc = function()
                  {
                        requestAnimationFrame(function fn(){
                          // if(self.top > self._top +5|| self.top < self._top -5){
                          //   range1 *= -1;
                          // }
                          // if(self.width > self._width +5|| self.width < self._width-5){
                          //   range2 *= -1;
                          // }
                          if(self.rotate > self._rotate+5 || self.rotate < self._rotate-5){
                            range3 *= -1;
                          }
                          self.top += range1 ;
                          self.left += range2 ;
                          // self.width += range2 ;
                          self.rotate += range3 ;
                          self.img.style.top = self.top+"%";
                          self.img.style.left = self.left+"%";
                          // self.img.style.width = self.width+"%";
                          
                          self.img.style.transform = "translate(-50%,-50%) rotate("+self.rotate+"deg)";
                          requestAnimationFrame(fn);
                        });
                        }

                   renderFunc();
              },
              destroyFunc:function(){
                if(document.getElementById('main'))
                 document.getElementById('main').removeChild( this.img);
              },
              render : function(){
                
              },
};
Date.prototype.Format = function (fmt) { 
    var o = {
        "M+": this.getMonth() + 1, //月份 
        "d+": this.getDate(), //日 
        "h+": this.getHours(), //小时 
        "m+": this.getMinutes(), //分 
        "s+": this.getSeconds(), //秒 
        "q+": Math.floor((this.getMonth() + 3) / 3), //季度 
        "S": this.getMilliseconds() //毫秒 
    };
    if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
    for (var k in o)
        if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
    return fmt;
};
  export default {
    name: 'Main',
    comments:{
      vueQr
    },
    data(){
      return{
          playerOptions: {
            height:window.innerHeight,
                    autoplay: true,
                    muted: false,
                    playbackRates: [0.7, 1.0, 1.5, 2.0],
                    sources: [{
                        type: "video/mp4",
                        src: "res/movie/21VInpassion_movie_211101_02.mp4",
                    }],
                },

        dialogTips:'',
        isWebARType:false,
        isShowARFrame:true,
        isShowARBtn:false,
        isCN:false,
        lgs:[{name:'日本語',type:'jp'},{name:'English',type:'en'},{name:'Français',type:'fr'},{name:'中文',type:'cn'}],
        lgIndex:0,
        showLg:false,
        needHide:false,
        isShowScroll:true,
        winWidth:window.innerWidth,
        winHeight:window.innerHeight,
        camera:null,
        scene:null,
        renderer:null,
        ambientLight:null,
        model_url:"../res/model/BBP_web_210925_00.fbx",
        mixer:null,
        clock:new THREE.Clock(),
        actionLimit:99,
        actionSpeed:1,
        actionTimefunc:null,
        needChange:false,
        shootUrl:"",
        timeouts:[],
        ani_temple_time:33,
        touch:{},
        move_distance:1,
        main_page_step:0,
        
        logo_url:"../res/texture/dp_logo_b.png",
        title_url:"../res/texture/bp_top_title.png",
        title_url_fr:"../res/texture/dp_lp_pc_fr_copy.png",
        title_url_cn:"../res/texture/dp_lp_pc_cn_copy.png",
        watage_url:"../res/texture/watage_monokuro_s.png",
        main_earth_url:"../res/texture/earth.gif",
        bless_earth_url:"../res/texture/BBP_web_taiki_210914_4.gif",
        bless_watege_url:"",
        btn_url:"../res/texture/button_name1_s.png",
        line_url:"../res/texture/line.png",
        w_line_url:"../res/texture/w_line.png",
        wait_url:"../res/texture/wait.png",
        ar_url:"../res/texture/ar.png",
        download_url:"../res/texture/icon_download_s.png",
        icon_1_url:"../res/texture/icon_home_s.png",
        icon_2_url:"../res/texture/icon_icon_bokin_s.png",
        icon_3_url:"../res/texture/icon_fb_s.png",
        icon_4_url:"../res/texture/insta_s.png",
        icon_5_url:"../res/texture/bp_lp_sns_line.png",
        icon_6_url:"../res/texture/dp_line_tsuika.png",
        icon_7_url:"../res/texture/dp_fb_tsuika.png",
        icon_8_url:"../res/texture/dp_line_insta.png",
        icon_7_url_en:"../res/texture/fb_banner_en_s.png",
        icon_8_url_en:"../res/texture/insta_banner_en_s.png",
        icon_9_url:"../res/texture/icon_wehcat.png",
        my_watage_url:"",
        username:"",
        usernameL:"",
        usernameF:"",
        cur_date:"",
        watages:[],
        praied:false,
        isShowQR:false,
        isShowTitle:false,
        isShowHideContent:false,
        isShowContent:false,
        isShowModel:false,
        isShowWeb:false,
        isShowIcons:false,
        isShowDialog:false,
        cameraY:17,
        cameraZ:90,
        earthTimeFunc:null,
        autoEarthTimeFunc:null,
        mjmn:null,
        needShowQR:false,
        needShowAccount:true,
        siteId:"s00100",
        waitWords:"",
        watageShape:0,
        watageColor:0,
        intId:"",
        qrString:"",
        pictureUrl:"../res/texture/waitBg/watage_bg_basic.jpg",
        curSite:this.$t('content.siteInfo')['s00100'],
        isPicReady:false,
        isAniReady:false,
        finalSiteId:"s00100",
        prayTime:"",
        prayWord:"",
        timePictureFunc:null,
        isNamed:false,
        showGif:false,
        wrongL:false,
        wrongF:false,
        watageLoaded:false,
        newImgData:null,
        isLoading:false,
        isConnecting:false,
        isPlay:false,
        isResetBg:false,
        curSiteRemoteInfo:{
                    name:"",
                    language:'',
                    siteNameJp:"",
                    siteNameEn:"",
                    qrDisplay: false,
                    accountDisplay: true},
        finishSiteRemoteInfo:{
                    name:"",
                    language:'',
                    siteNameJp:"",
                    siteNameEn:"",
                    qrDisplay: false,
                    accountDisplay: true},
        isBlackBg:false,
        watageLong:false,
        moneyType:0
      }
    },
    created(){
        this.getUrlArgs();
    },
    computed:{
      currentFont:function()
      {
        switch(this.lgIndex){
          case 0:
            return 'font-Regular-jp';
          case 1:
            return 'font-Regular-en';
          case 2:
            return 'font-Regular-en';
          case 3:
            return 'font-Regular-cn';
        }
        return 'font-Regular-jp';
      },
      currentBoldFont:function()
      {
        switch(this.lgIndex){
          case 0:
            return 'font-Bold-jp';
          case 1:
            return 'font-Bold-en';
          case 2:
            return 'font-Bold-en';
          case 3:
            return 'font-Bold-cn';
        }
        return 'font-Bold-jp';
      },
       currentMediumFont:function()
      {
        switch(this.lgIndex){
          case 0:
            return 'font-Medium-jp';
          case 1:
            return 'font-Medium-en';
          case 2:
            return 'font-Medium-en';
          case 3:
            return 'font-Medium-cn';
        }
        return 'font-Medium-jp';
      }
     
     
    },
    mounted(){
      if(this.getCookie("lang")!=null){
          this.$i18n.locale = this.getCookie("lang");
      }
      this.initThree();
      var self = this;
      setInterval(()=>{
        self.setCurDate()
      },1000);
    },
    methods:{
    onloadGif(){

        if(this.main_page_step==-1)
          this.main_page_step ++;
      // setTimeout(()=>{
      //   if(this.main_page_step==-1)
      //     this.main_page_step ++;
      // },46000)
    },
      backToMain(){
        this.main_page_step =0;
        this.usernameL = "";
        this.usernameF = "";
        this.isNamed = false;
        this.isShowTitle=false;
        this.isShowHideContent=false;
        this.isShowContent=false;
        this.isShowModel=false;
        this.isShowTitle=false;
        this.isPlay = false;
        if(this.action){
          this.actionSpeed = 1;
          this.action.time = 0;
        }
        this.needChange = false;
        this.cameraY=17;
        this.cameraZ=90;
        this.camera.position.set(0,  this.cameraY,  this.cameraZ);

      },
    logEvent(ev_name){
      logEvent(analytics,ev_name);
    },
    onClickCtr1(){
      window.open("#");

      this.logEvent('endView_support');
    },
    getUrlArgs(){

        this.logEvent('openLoginView');
        var url = window.location.href ;
        var dz_url = url.split('#')[0];
        var cs = dz_url.split('?')[1];
        if(cs){

        var cs_arr = cs.split('&');
        var args={};
        for(var i=0;i<cs_arr.length;i++){
          args[cs_arr[i].split('=')[0]] = cs_arr[i].split('=')[1]
          }
          if(args.siteid)
          {
            this.siteId = args.siteid;
          }
          if(args.id)
          {
            this.siteId = args.id;
          }
        }
        if(this.siteId == 's00112'){
          this.isWebARType = true;
          this.main_page_step = -1;
        }else{

          this.isWebARType = false;
          this.main_page_step = 0;
        }
        if(this.siteId == 's00117' || this.siteId == 's00100'){
            this.isCN = true;
            this.lgIndex = 3;
           this.onClickLg(3);
        }
          if(this.$t('content.siteInfo')[this.siteId])
        {

              this.curSite =  this.$t('content.siteInfo')[this.siteId];
        }else{
               this.curSite =  this.$t('content.siteInfo')['s00100'];
        }
        var self = this;
       
        netMgr.RequestSiteInfo(this.siteId,(data)=>{

            if(data!=null)
            {
              if(!data.code)
              {
                  self.curSiteRemoteInfo.qrDisplay = data.qrDisplay;
                  self.curSiteRemoteInfo.accountDisplay = data.accountDisplay;
                  self.curSiteRemoteInfo.language = data.language;
                  self.curSiteRemoteInfo.name = data.language=='jp'?data.siteNameJp:data.siteNameEn;
                  if(data.language)
                  {
                      self.isCN = data.language == 'cn';
                      if(this.siteId == 's00117'  || this.siteId == 's00100'){
                        this.isCN = true;
                          this.lgIndex = 3;
                        this.onClickLg(3);
                      }else{
                            for(var i in self.lgs)
                            {
                              
                              if(data.language == self.lgs[i].type){
                                self.onClickLg(i);
                              }

                            }
                      }
                    
                  }
                  
              }
            }
        })
      

        this.logEvent('openLoginView_'+this.curSite.id);
      },
      login(){
        this.main_page_step ++;
        this.logEvent('loginView_login')
       
      },
      showQR()
      {
        this.isShowQR = true;
        document.documentElement.style.overflow='hidden';
      },
      closeQR(){

        this.isShowQR = false;
        document.documentElement.style.overflow='auto';
      },
      showDialog(){
          this.isShowDialog = true;
          document.documentElement.style.overflow='hidden';
      },
      closeDialog(){
          this.isShowDialog = false;
          document.documentElement.style.overflow='auto';
      },
       getCookie(objName) {
        var arrStr = document.cookie.split("; ");
        for (var i = 0; i < arrStr.length; i++) {
            var temp = arrStr[i].split("=");
            if (temp[0] == objName) {
                return decodeURI(temp[1]);
            }
        }
        return null;
    },
    setCookie(name, value) {
         if(name=='lang'){

             document.cookie = name + "=" + encodeURI(value) + ";expires=Thu, 1-Jan-2030 00:00:00 GMT; domain=.breathbless.com;path:/";
         }else
         {
             document.cookie = name + "=" + encodeURI(value) + ";expires=Thu, 1-Jan-2030 00:00:00 GMT;path:/";
         }
    },
      onClickLg(_index)
      {
        this.showLg =false;
        this.lgIndex = _index;
        this.$i18n.locale = this.lgs[_index].type;
        this.setCookie('lang',this.lgs[_index].type);
      },
      hueRotate(rotation) {
          rotation = (rotation || 0) / 180 * Math.PI;
          var cosR = Math.cos(rotation),
          sinR = Math.sin(rotation),
          sqrt = Math.sqrt;
          var w = 1 / 3,
          sqrW = sqrt(w);
          var a00 = cosR + (1.0 - cosR) * w;
          var a01 = w * (1.0 - cosR) - sqrW * sinR;
          var a02 = w * (1.0 - cosR) + sqrW * sinR;
          var a10 = w * (1.0 - cosR) + sqrW * sinR;
          var a11 = cosR + w * (1.0 - cosR);
          var a12 = w * (1.0 - cosR) - sqrW * sinR;
          var a20 = w * (1.0 - cosR) - sqrW * sinR;
          var a21 = w * (1.0 - cosR) + sqrW * sinR;
          var a22 = cosR + w * (1.0 - cosR);
          var matrix = [
            a00, a01, a02, 0, 0,
            a10, a11, a12, 0, 0,
            a20, a21, a22, 0, 0,
            0, 0, 0, 1, 0,
          ];
          return matrix;
      },
    downloadFile(fileName, content) {
    let aLink = document.createElement('a');
    let blob = this.base64ToBlob(content); //new Blob([content]);

    let evt = document.createEvent("HTMLEvents");
    evt.initEvent("click", true, true);//initEvent 不加后两个参数在FF下会报错  事件类型，是否冒泡，是否阻止浏览器的默认行为
    aLink.download = fileName;
    aLink.href = URL.createObjectURL(blob);

    // aLink.dispatchEvent(evt);
    aLink.click()
  },
  //base64转blob
   base64ToBlob(code) {
    let parts = code.split(';base64,');
    let contentType = parts[0].split(':')[1];
    let raw = window.atob(parts[1]);
    let rawLength = raw.length;

    let uInt8Array = new Uint8Array(rawLength);

    for (let i = 0; i < rawLength; ++i) {
      uInt8Array[i] = raw.charCodeAt(i);
    }
    return new Blob([uInt8Array], {type: contentType});
  },

  resetImg(){
    if(!this.isResetBg)
    {
      this.isResetBg = true;

   var img = document.getElementById('downlodBg')
    var base64 = this.getBase64Image(img);// 将图片转成base64格式的方法
    img.setAttribute('src',base64); // 将原来的图片src改为base64格式的地址
    }
},
 getBase64Image(img){
    var canvas = document.createElement("canvas");// 创建一个canvas
    canvas.width = img.width; // 设置对应的宽高
    canvas.height = img.height;
    var ctx = canvas.getContext("2d"); // 二维绘图环境
    ctx.drawImage(img, 0, 0, img.width, img.height); // 将图片画在画布上
    var ext = img.src.substring(img.src.lastIndexOf(".") + 1).toLowerCase(); // 获取到图片的格式
    var dataURL = canvas.toDataURL("image/" + ext); // 得到base64 编码的 dataURL
    return dataURL;
},
      downloadImg(){

        this.logEvent('endView_download');
        this.isLoading = true;
        this.isConnecting = true;
        html2canvas(this.$refs.downloadImg,{
              width:this.$refs.downloadImg.width,
              height:this.$refs.downloadImg.height,
              useCORS:true
        }).then((canvas1)=>{
              // const ctx = canvas1.getContext('2d')

              // ctx.drawImage(document.getElementById('testimg'),0,0);
              // var oimg = ctx.getImageData(0, 0, canvas1.width,canvas1.height);
              // var rotateMX = this.hueRotate(86);
              // // 反转颜色
              // for (var i = 0; i < oimg.data.length; i += 4) {
              //     var r = oimg.data[i] * rotateMX[0] + oimg.data[i + 1]* rotateMX[1] +  oimg.data[i + 2]* rotateMX[2];
              //     var g = oimg.data[i] * rotateMX[5] + oimg.data[i + 1]* rotateMX[6] +  oimg.data[i + 2]* rotateMX[7];
              //     var b = oimg.data[i] * rotateMX[10] + oimg.data[i + 1]* rotateMX[11] +  oimg.data[i + 2]* rotateMX[12];
              //       oimg.data[i] = r>255?255:r;
              //       oimg.data[i + 1] =  g>255?255:g;
              //       oimg.data[i + 2] =  b>255?255:b;
              // }
              // ctx.putImageData(oimg, 0, 0);
              this.downloadFile(new Date().getTime()+"",canvas1.toDataURL());
              // var blob=new Blob([''], {type:'application/octet-stream'});
              // var url = URL.createObjectURL(blob);
              // var a = document.createElement('a');
              // a.href = canvas1.toDataURL('image/png',1);
              // a.download = new Date().getTime();
              // var e = document.createEvent('MouseEvents');
              // e.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
              // a.dispatchEvent(e);
              // URL.revokeObjectURL(url);
          
          this.isLoading = false;
          this.isConnecting = false;
        })
      },
     test(){
  
     },
     createWatage(){
       this.watages.push(new Watege(this.watageColor));
          
     },
     createFloatWatage()
     {

       this.watages.push(new FloatWatege(this.watages.length,this.watageColor));
     },
     loadWatage(){
  
       var self = this;
        if(!self.watageLoaded){
            self.watageLoaded = true;
            var img = document.getElementById('watage_img_user');
            img.style.filter = "hue-rotate("+self.watageColor+"deg)";
            img.style.top = "50%";
            img.style.left = "50%";
            var width = 90;
            var top = 50;
            var range1 = 0.03;
            var range2 = 0.02;
            var range3 = -0.04;
            var rotate = 0;
            var renderFunc = function()
            {
            requestAnimationFrame(function fn(){
              if(top > 55 || top < 45){
                range1 *= -1;
              }
              if(width > 95|| width < 85){
                range2 *= -1;
              }
              if(rotate > 5 || rotate < -5){
                range3 *= -1;
              }
              top += range1 ;
              width += range2 ;
              rotate += range3 ;
              img.style.top = top+"%";
              img.style.left = "50%";
              img.style.width = width+"%";
              
              img.style.transform = "translate(-50%,-50%) rotate("+rotate+"deg)";
               requestAnimationFrame(fn);
            });
             
            }
            renderFunc();
            }
     },
     genFinalWatage(){
       
       var self = this;
      
        var clone = document.getElementById('watage_img_user').cloneNode(true);
        clone.style.left = "-100%";
        clone.style.fontSize = "190%";
         document.getElementById('main').appendChild( clone );
        html2canvas(clone,{
              width:clone.width,
              height:clone.height,
              backgroundColor:null
        }).then((canvas1)=>{
              const ctx = canvas1.getContext('2d')
              // ctx.drawImage(document.getElementById('testimg'),0,0);
              var oimg = ctx.getImageData(0, 0, canvas1.width,canvas1.height);
              // var rotateMX = this.hueRotate(this.watageColor);
              // // 反转颜色
              if(self.isBlackBg)
              {
                  for (var i = 0; i < oimg.data.length; i += 4) {
                                    // var r = oimg.data[i] * rotateMX[0] + oimg.data[i + 1]* rotateMX[1] +  oimg.data[i + 2]* rotateMX[2];
                                    // var g = oimg.data[i] * rotateMX[5] + oimg.data[i + 1]* rotateMX[6] +  oimg.data[i + 2]* rotateMX[7];
                                    // var b = oimg.data[i] * rotateMX[10] + oimg.data[i + 1]* rotateMX[11] +  oimg.data[i + 2]* rotateMX[12];
                                      // oimg.data[i] = r>255?255:r;
                                      // oimg.data[i + 1] =  g>255?255:g;
                                      // oimg.data[i + 2] =  b>255?255:b;
                                      
                                      oimg.data[i] =255;
                                      oimg.data[i + 1] =  255;
                                      oimg.data[i + 2] =  255;
                                }
              }else{
                    for ( i = 0; i < oimg.data.length; i += 4) {
                        oimg.data[i] =71;
                        oimg.data[i + 1] =  69;
                        oimg.data[i + 2] =  65;
                     }
              }
              
              ctx.putImageData(oimg, 0, 0);
              self.newImgData  =canvas1.toDataURL('image/png',2); 
                
               self.isLoading =false;
        })
        
       
                  
     },
      sendPrayerFuncAR()
     {
       if(!this.praied){
          this.praied = true;  
          this.logEvent('QRView_pray_AR')
          var self = this;
          this.isLoading = true;
          this.isConnecting = true;
                     
          netMgr.RequestPray(this.intId,this.siteId,(data)=>{
                  this.isLoading = false;
                  this.isConnecting = false;
              if(data!=null){
                    self.isBlackBg = !data.isLight;
                    self.genFinalWatage();
                    // self.showGif = true;
                    self.isPicReady = true;
                    if(data.pictureURL)
                      self.pictureUrl = data.pictureURL;
                    if(data.finalSiteId)
                      self.finalSiteId = data.finalSiteId;
                    self.isResetBg = false;
                    netMgr.RequestSiteInfo(self.finalSiteId,(_data)=>{
                          if(_data!=null)
                          {
                                if(!data.code)
                                  {
                                      if(self.finalSiteId != "s00100"){
                                        
                                          self.finishSiteRemoteInfo.name = self.curSiteRemoteInfo.language == "jp"? _data.siteNameJp:_data.siteNameEn;
                                      }else{
                                        self.finishSiteRemoteInfo.name = "";
                                      }
                                      self.finishSiteRemoteInfo.qrDisplay = _data.qrDisplay;
                                      self.finishSiteRemoteInfo.accountDisplay = _data.accountDisplay;


                                  }
                          }
                          
                          self.prayWord = '@'+self.finishSiteRemoteInfo.name;
                          if(self.curSite.id == "s00100" || self.curSite.id == "s00112")
                          {

                            self.waitWords = self.$t('content.watege4')  +self.finishSiteRemoteInfo.name +self.$t('content.watege5');
                          }else{

                            self.waitWords = self.$t('content.watege1') + self.curSiteRemoteInfo.name+self.$t('content.watege2') +self.finishSiteRemoteInfo.name +self.$t('content.watege3');
                          }

                    });
                   
                    if(data.timestamp)
                    {
                      var t = new Date( data.timestamp);


                      self.prayTime = t.Format("yyyy.MM.dd hh:mm");
                    }
                  if(self.isPicReady)
                      self.main_page_step ++;

               
                setTimeout(()=>{
                  document.documentElement.style.overflow='hidden';
                  var mynum = 3+2*Math.random();
                    for(var j= 0;j<mynum;j++)
                  {
                        setTimeout(
                              ()=>{

                          self.createFloatWatage();
                              },33*j
                          )
                  }
                },100);
                setTimeout(()=>{
                    for(var i in  self.watages)
                    {
                      self.watages[i].destroyFunc();
                    }
                    self.watages.splice(0);
                    self.main_page_step ++;
                    document.documentElement.style.overflow='auto'; 
                },10000);
                    
                    
              }else{
                this.praied = false;
              }
        
          })
          document.documentElement.style.overflow='hidden';
          
       }
     },
     sendPrayerFunc()
     {
       if(!this.praied){
          this.praied = true;  
          this.logEvent('QRView_pray')
          document.getElementById('bless_watage').style.filter = "hue-rotate("+this.watageColor+"deg)";
          var self = this;
          this.isLoading = true;
          this.isConnecting = true;
                     
          netMgr.RequestPray(this.intId,this.siteId,(data)=>{
                  this.isLoading = false;
                  this.isConnecting = false;
              if(data!=null){
                    self.isBlackBg = !data.isLight;
                    self.genFinalWatage();
                    self.showGif = true;
                    self.isPicReady = true;
                    if(data.pictureURL)
                      self.pictureUrl = data.pictureURL;
                    if(data.finalSiteId)
                      self.finalSiteId = data.finalSiteId;
                    self.isResetBg = false;
                    netMgr.RequestSiteInfo(self.finalSiteId,(_data)=>{
                          if(_data!=null)
                          {
                                if(!data.code)
                                  {
                                      if(self.finalSiteId != "s00100"){
                                        self.finishSiteRemoteInfo.name = self.curSiteRemoteInfo.language == "jp"? _data.siteNameJp:_data.siteNameEn;
                                      }else{
                                        self.finishSiteRemoteInfo.name = "";
                                      }
                                      self.finishSiteRemoteInfo.qrDisplay = _data.qrDisplay;
                                      self.finishSiteRemoteInfo.accountDisplay = _data.accountDisplay;

                                  }
                          }
                          
                          self.prayWord = '@'+self.finishSiteRemoteInfo.name;
                          if(self.curSite.id == "s00100" || self.curSite.id == "s00112")
                          {

                            self.waitWords = self.$t('content.watege4')  +self.finishSiteRemoteInfo.name +self.$t('content.watege5');
                          }else{

                            self.waitWords = self.$t('content.watege1') + self.curSiteRemoteInfo.name+self.$t('content.watege2') +self.finishSiteRemoteInfo.name +self.$t('content.watege3');
                          }

                    });
                   
                    if(data.timestamp)
                    {
                      var t = new Date( data.timestamp);


                      self.prayTime = t.Format("yyyy.MM.dd hh:mm");
                    }
                    if(self.isPicReady && self.isAniReady)
                      self.main_page_step ++;
                  // setTimeout(()=>{

                  var mynum1 = 8+2*Math.random();

                  var mynum2 = 20+5*Math.random();
                      for(var i= 0;i<mynum1;i++)
                          setTimeout(
                              ()=>{

                          self.createWatage();
                              },33*i
                          )
                        for( i= 0;i<mynum2;i++)
                          setTimeout(
                              ()=>{

                          self.createWatage();
                              },200*i
                          )
                        // },4000);
                  
                
                  setTimeout(()=>{
                    for(var i in  self.watages)
                    {
                      self.watages[i].destroyFunc();
                    }
                    self.watages.splice(0);
                    self.main_page_step++;
                },11000);
                setTimeout(()=>{
                  document.documentElement.style.overflow='hidden';
                  var mynum = 3+2*Math.random();
                    for(var j= 0;j<mynum;j++)
                  {
                        setTimeout(
                              ()=>{

                          self.createFloatWatage();
                              },33*j
                          )
                  }
                },12500);
                setTimeout(()=>{
                    for(var i in  self.watages)
                    {
                      self.watages[i].destroyFunc();
                    }
                    self.watages.splice(0);
                    self.isAniReady = true;  
                    if(self.isPicReady && self.isAniReady)
                      self.main_page_step ++;
                    document.documentElement.style.overflow='auto'; 
                },22000);
                    
              }else{
                this.praied = false;
              }
        
          })
          document.documentElement.style.overflow='hidden';
          
       }
     },
      oncanPlay()
      {
        
      },
      showWebJoin(){
        this.main_page_step ++;
        this.logEvent('QRView_webJoin');
        if(this.timePictureFunc)
            clearInterval(this.timePictureFunc);
      },
        showWebJoinAR(){
          var self = this;
        this.main_page_step ++; 

        this.initThreeAR();
        setTimeout(()=>{

                      self.isShowARFrame = false;
        },10000);
         webAR.openCamera({ audio: false, video: { facingMode: { exact: 'environment' } } })
                .then(msg => {
                    console.info(msg);
                    webAR.startRecognize((msg) => {
                      console.info(msg);
                      console.log("success");
                      self.initFBXAR();
                  });
                }).catch(err => {
                    console.info(err);
                });
        this.logEvent('QRView_webJoinAR');
        if(this.timePictureFunc)
            clearInterval(this.timePictureFunc);
      },
      showWebQRJoin(){

        this.logEvent('QRView_webJoinQR');
        if(this.timePictureFunc)
            clearInterval(this.timePictureFunc);

        if(!this.praied){
          this.praied = true;
          var self = this;
          this.isLoading = true;
          this.isConnecting = true;
                     
          netMgr.RequestPray(this.intId,this.siteId,(data)=>{
                  this.isLoading = false;
                  this.isConnecting = false;
              if(data!=null){
                    self.isBlackBg = !data.isLight;
                    self.genFinalWatage();
                    self.isPicReady = true;
                    if(data.pictureURL)
                      self.pictureUrl = data.pictureURL;
                    if(data.finalSiteId)
                      self.finalSiteId = data.finalSiteId;
                    self.isResetBg = false;
                    netMgr.RequestSiteInfo(self.finalSiteId,(_data)=>{
                          if(_data!=null)
                          {
                                if(!data.code)
                                  {
                                      if(self.finalSiteId != "s00100"){
                                        self.finishSiteRemoteInfo.name = self.curSiteRemoteInfo.language == "jp"? _data.siteNameJp:_data.siteNameEn;
                                      }else{
                                        self.finishSiteRemoteInfo.name = "";
                                      }
                                      self.finishSiteRemoteInfo.qrDisplay = _data.qrDisplay;
                                      self.finishSiteRemoteInfo.accountDisplay = _data.accountDisplay;

                                  }
                          }
                          
                          self.prayWord = '@'+self.finishSiteRemoteInfo.name;
                          if(self.curSite.id == "s00100" || self.curSite.id == "s00112")
                          {

                            self.waitWords = self.$t('content.watege4')  +self.finishSiteRemoteInfo.name +self.$t('content.watege5');
                          }else{

                            self.waitWords = self.$t('content.watege1') + self.curSiteRemoteInfo.name+self.$t('content.watege2') +self.finishSiteRemoteInfo.name +self.$t('content.watege3');
                          }

                    });
                   
                    if(data.timestamp)
                    {
                      var t = new Date( data.timestamp);


                      self.prayTime = t.Format("yyyy.MM.dd hh:mm");
                    }
                    if(self.isPicReady)
                      self.main_page_step +=2;
                  // setTimeout(()=>{

               
                setTimeout(()=>{
                  document.documentElement.style.overflow='hidden';
                  var mynum = 3+2*Math.random();
                    for(var j= 0;j<mynum;j++)
                  {
                        setTimeout(
                              ()=>{

                          self.createFloatWatage();
                              },33*j
                          )
                  }
                },100);
                setTimeout(()=>{
                    for(var i in  self.watages)
                    {
                      self.watages[i].destroyFunc();
                    }
                    self.watages.splice(0);
                    self.main_page_step ++;
                    document.documentElement.style.overflow='auto'; 
                },10000);
                    
              }else{
                this.praied = false;
              }
        
          })
          document.documentElement.style.overflow='hidden';
          
       }
      },
      generateColorByName()
      {
        var total = 0;
        this.usernameF = this.usernameF.toUpperCase();
        this.usernameL = this.usernameL.toUpperCase();
        this.username = this.usernameF+this.usernameL;
        if(this.usernameL.length > 7 || this.usernameF.length > 7){
          this.watageLong = true;
        }else{
          this.watageLong = false;
        }
        var len = 0;
        for(var i in this.username)
        {
          total += this.username.charCodeAt(i);
          len++;
        }
        var down = total % 360;
        var type = len % 4;
        this.watageShape = type;
        this.watageColor = down;
        this.bless_watege_url = "../res/texture/BBP_web_blow_"+(type==0?'a':type==1?'b':type==2?'c':type==3?'d':'e')+".gif?a="+Math.random();
        this.my_watage_url = "../res/texture/watage_color_"+(type==0?'a':type==1?'b':type==2?'c':type==3?'d':'e')+".png";
     
            
      },
    
      sendNameToServer(){
        if(this.checkNameType()){
              this.dialogTips = this.$t('content.nameWraning');
              this.isShowDialog = true;
              return;
        }
         if(this.usernameF == '' && this.usernameL == '')
          { 
            
              this.dialogTips = this.$t('content.nameWraning');
              this.isShowDialog = true;
          }else{
            if(!this.isNamed)
            {
              this.logEvent('nameView_submit');
              // this.isLoading = true;
              this.isConnecting = true;
              this.isNamed = true;
              var self = this;
              netMgr.RequestSubmitName(this.usernameF,this.usernameL,this.watageShape,this.watageColor,this.$i18n.locale,(data)=>{

                    
                          self.isConnecting = false;  
                 if(data!=null)
                  {
                    if(data.code){

                        self.dialogTips = self.$t('content.ngWraning');
                            self.isShowDialog = true;
                            self.isNamed = false;
                    }else{
                        self.generateColorByName();
                      self.main_page_step ++;
                      self.intId = data.intId;
                       self.qrString = data.QRString; 
                      if(self.curSiteRemoteInfo.qrDisplay)
                     {
                           self.timePictureFunc = setInterval(()=>{
                                      self.timePictureFuncRequest();
                                  },2000);
                      }
                    }
                     
                  }else{
                      self.isNamed = false;
                  }
                   
            });
            }
           
          }

      },
      timePictureFuncRequest()
      {
          var self = this;
          netMgr.RequestGetPicture(self.intId,(data)=>{
            if(data!=null)
            {
               if(!data.code){
                 self.isBlackBg = !data.isLight;
                 self.genFinalWatage();
                if(data.pictureURL)
                  self.pictureUrl = data.pictureURL;
                if(data.finalSiteId)
                  self.finalSiteId = data.finalSiteId;
                self.isPicReady =true;  
                  netMgr.RequestSiteInfo(self.finalSiteId,(_data)=>{
                          if(_data!=null)
                          {
                                if(!data.code)
                                  {
                                      if(self.finalSiteId != "s00100"){
                                        
                                        self.finishSiteRemoteInfo.name = self.curSiteRemoteInfo.language == "jp"? _data.siteNameJp:_data.siteNameEn;
                                      }else{
                                        self.finishSiteRemoteInfo.name = "";
                                      }
                                      self.finishSiteRemoteInfo.qrDisplay = _data.qrDisplay;
                                      self.finishSiteRemoteInfo.accountDisplay = _data.accountDisplay;

                                  }
                          }
                          
                          self.prayWord = '@'+self.finishSiteRemoteInfo.name;
                          if(self.curSite.id == "s00100" || self.curSite.id == "s00112")
                          {

                            self.waitWords = self.$t('content.watege4')  +self.finishSiteRemoteInfo.name +self.$t('content.watege5');
                          }else{

                            self.waitWords = self.$t('content.watege1') + self.curSiteRemoteInfo.name+self.$t('content.watege2') +self.finishSiteRemoteInfo.name +self.$t('content.watege3');
                          }

                    });
                if(data.timestamp){
                  var t = new Date( data.timestamp);
                  self.prayTime = t.Format("yyyy.MM.dd hh:mm");
                }

                self.main_page_step = 5;
                document.documentElement.style.overflow='hidden';
                var mynum = 3+2*Math.random();
                for(var j= 0;j<mynum;j++)
                {
                        setTimeout(
                              ()=>{

                          self.createFloatWatage();
                              },33*j
                          )
                }
                setTimeout(()=>{
                    for(var i in  self.watages)
                    {
                      self.watages[i].destroyFunc();
                    }
                    self.watages.splice(0);
                    self.isAniReady = true;  
                    if(self.isPicReady && self.isAniReady)
                      self.main_page_step ++;
                    document.documentElement.style.overflow='auto'; 
                },10000);
                if(self.timePictureFunc){
                    clearInterval(self.timePictureFunc);
                }
            }
            }
           
            
          });
          
      },
      checkNameType()
      {
        var reg1 = new RegExp(/[^a-zA-Z]/g) ;
        var reg2 = new RegExp(/[^a-zA-Z]/g) ;
        this.wrongL = reg2.test(this.usernameL);
        this.wrongF = reg1.test(this.usernameF);
        return (this.wrongL || this.wrongF)
         
      },
      mouseDown(ev){
         let sp = document.getElementById("lgTarget");
        if(sp){
          if(!sp.contains(ev.target)){
            this.showLg = false;
          }
       }
      },
      mouseWheel(ev)
      {
        if(ev.deltaY < 0)
        {
          this.touchFingerDown();
        }else{
          this.touchFingerUp();
        }
      },
      touchStart(ev)
      {
        this.touch.x1 = ev.changedTouches[0].pageX;
        this.touch.y1 = ev.changedTouches[0].pageY;
       let sp = document.getElementById("lgTarget");
        if(sp){
          if(!sp.contains(ev.target)){
            this.showLg = false;
          }
       }
        
      },
      touchMove(ev)
      {
        if(this.main_page_step==1){

             ev.preventDefault()
        }

      },
      touchEnd(ev)
      {
          this.touch.x2 = ev.changedTouches[0].pageX;
          this.touch.y2 = ev.changedTouches[0].pageY;
          let h = Math.abs(this.touch.y1 - this.touch.y2);
          if(h > this.move_distance)
          {
            if(this.touch.y2 > this.touch.y1){
              this.touchFingerDown();
            }else{
              this.touchFingerUp()
            }
          }
      },
      touchFingerUp()
      {
            this.checkStepAction(true);
        

      },
      touchFingerDown()
      {
            this.checkStepAction(false);
        
      },
      clearTimeouts()
      {
         if(this.timeouts){
                    for(var i=0;i<this.timeouts.length;i++){
                        clearTimeout(this.timeouts[i]);
                    }
                    this.timeouts.splice(0);
                }
      },
      checkStepAction(isBack)
      {
                              var self = this;
        if(this.main_page_step == 1)
        {
              if(this.isShowTitle)
              {
                if(this.isShowContent)
                {
                  if(this.isShowModel){
                  if(this.isShowHideContent)
                    {
                      this.needChange = true;

                          if(this.action)
                          {
                                  this.action.enabled = true;
                            clearTimeout(this.actionTimefunc);
                                this.actionTimefunc = setTimeout(()=>{
                                  if(self.action){

                                  self.action.enabled = true;
                                  self.needChange  = false;
                                  self.action.timeScale = 1;
                                  }
                                },2000);
                          if(isBack)
                          {
                              this.actionSpeed += 5;
                              if(this.actionSpeed > this.actionLimit)
                                {
                                  this.actionSpeed = this.actionLimit;
                                }
                          
                          }else{
                          
                              this.actionSpeed -= 5 ;
                              if(this.actionSpeed < -this.actionLimit)
                                {
                                  this.actionSpeed = -this.actionLimit;
                                }
                          }
                              this.action.timeScale = this.actionSpeed;
                          }
                    }else{
                      this.isShowScroll = false;
                      this.isShowHideContent = true;
                      this.earthTimeFunc = setInterval(() => {
                           if(self.cameraY > 2)
                              self.cameraY-=0.35;

                           if(self.cameraZ >45)
                              self.cameraZ-=1.17;
                            if(self.cameraY <= 2 && self.cameraZ <= 45)
                              clearInterval(self.earthTimeFunc)
                          self.camera.position.set(0,  self.cameraY,  self.cameraZ)
                      }, 33);
                      if(this.autoEarthTimeFunc){
                        clearTimeout(this.autoEarthTimeFunc);
                        this.autoEarthTimeFunc = null;
                      }
                    }
                  }else{
                    if(isBack)
                      this.isShowModel = true;
                        if(this.action){
                            this.isPlay = true;
                            this.action.play();
                      }
                     this.autoEarthTimeFunc = setTimeout(()=>{
                       this.checkStepAction(true)
                     },4000);

                  }
                      
                  
                }else{

                   if(isBack){
                     this.isShowContent = true;
                    
                   }
                }
              }else{
                if(isBack)
                    this.isShowTitle = true;
              }
        }else if(this.main_page_step == 6)
        {
            if(!this.isShowIcons){
               this.isShowIcons = true;
            }
        }
        else if(this.main_page_step == 3)
        {
            if(!this.isShowWeb){
               this.isShowWeb = true;
            }
        }
        
          
      },

      initThreeAR(){
        //////////////////////////////////////////////////////////////////////////////////
        //		Init
        //////////////////////////////////////////////////////////////////////////////////
       this.rendererAR = new THREE.WebGLRenderer({
            antialias: true,
            alpha: true,
        });

       this.clockAR= new THREE.Clock();

        this.mixerAR= null;
        this.actionAR= null;

        this.rendererAR.setPixelRatio(window.devicePixelRatio);

        this.rendererAR.setSize( this.winWidth, this.winHeight );
        this.rendererAR.setClearColor(new THREE.Color('lightgrey'), 0)
        document.getElementById('ARRender').appendChild( this.rendererAR.domElement );
        window.addEventListener('resize', this.onWindowResizeAR, false);
        this.sceneAR = new THREE.Scene();
        this.cameraAR = new THREE.PerspectiveCamera(45, this.winWidth/this.winHeight, 0.1, 10000);
        this.cameraAR.position.set(0,0, 300);
        this.sceneAR.add(this.cameraAR);  
        var s = new THREE.AmbientLight(0Xffffff);
        this.sceneAR.add(s);
        this.renderSceneAR();
    
     
      },

            renderSceneAR(){
                requestAnimationFrame(this.renderSceneAR);
                this.rendererAR.render(this.sceneAR, this.cameraAR);
                if(this.mixerAR)
                  this.mixerAR.update(this.clockAR.getDelta());
                
                if(this.actionAR){
                  if(this.actionAR.time > 20){
                    this.isShowARBtn = true;
                  }
                }
               
               
            },
            initThree(){
                this.initScene();
                this.initCamera();
                this.initRender();
                this.createAmbientLight();
                this.initFBX();
                this.renderScene();
            },
            initScene(){
                this.scene = new THREE.Scene();
            },
            initRender(){
                this.render = new THREE.WebGLRenderer( {antialias: true, alpha: true} ); // antialias 抗锯齿
                this.render.setSize(this.winWidth, this.winHeight);
                // this.render.shadowMap.enabled = true; // 允许阴影投射

                document.getElementById('MainRender').appendChild(this.render.domElement);
                window.addEventListener('resize', this.onWindowResize, false);

            },
            initCamera(){
                this.camera = new THREE.PerspectiveCamera(45, this.winWidth/this.winHeight, 0.1, 10000);
                // this.camera = new THREE.PerspectiveCamera(this.winWidth / - 2, this.winWidth / 2,this.winHeight / 2, this.winHeight/ - 2, 0.1, 1000 )
                this.camera.position.set(0,this.cameraY, this.cameraZ)
                // this.camera.lookAt(new THREE.Vector3(0,-100,-01));

            },
        
            
            onWindowResize(){
                this.winWidth = window.innerWidth;
                this.winHeight = window.innerHeight ;
                // this.camera.left =    -this.winWidth/2 ;// 固定的30unit
                // this.camera.right =    this.winWidth/2 ;// 固定的30unit
                // this.camera.top =    this.winHeight/2 ;// 固定的30unit
                // this.camera.bottom =    -this.winHeight/2 ;// 固定的30unit
                // this.camera.updateProjectionMatrix();
                this.render.setSize(this.winWidth, this.winHeight);
            }, 
            onWindowResizeAR(){
                // this.camera.left =    -this.winWidth/2 ;// 固定的30unit
                // this.camera.right =    this.winWidth/2 ;// 固定的30unit
                // this.camera.top =    this.winHeight/2 ;// 固定的30unit
                // this.camera.bottom =    -this.winHeight/2 ;// 固定的30unit
                // this.camera.updateProjectionMatrix();
                this.rendererAR.setSize(this.winWidth, this.winHeight);
            },
           

            createAmbientLight() {
                this.ambientLight = new THREE.AmbientLight(0Xffffff,1.5);
                this.scene.add(this.ambientLight);
                // var s = new THREE.DirectionalLight(0xffffff,10);
                // var obj = new THREE.Object3D();
                // obj.position.set(100,100,100);
                // s.target= obj
                // s.position.set(0,0,0);
                // s.target = this.camera;
                // this.scene.add(this.ambientLight);
                // this.scene.add(s);
            },
            

            renderScene(){
                requestAnimationFrame(this.renderScene);
                this.render.render(this.scene, this.camera);
                if(this.mixer)
                  this.mixer.update(this.clock.getDelta());
                if(this.action && this.isShowModel&& this.isPlay)
                {
                  if(this.needChange)
                  {
                    if(this.actionSpeed>0)
                    {
                      this.actionSpeed -= 0.05;
                      if(this.actionSpeed <0){
                        this.actionSpeed =0;
                      }
                    }else{
                       this.actionSpeed += 0.05;
                      if(this.actionSpeed >0){
                        this.actionSpeed =0;
                      }
                    }
                    this.action.timeScale = this.actionSpeed;
                    
                  }
                  if(this.action.time >=31  ){
                    this.isPlay = false;
                    this.main_page_step++;
                  }
                }
               
            },
            getLocalTime(i) {
              if (typeof i !== "number") {
                return new Date();
              }
              var d = new Date();
              var len = d.getTime();
              var offset = d.getTimezoneOffset() * 60000;
              var utcTime = len + offset;
              return new Date(utcTime + 3600000 * i);
            },
            setCurDate(){
              var t = new Date();
              var timeZone = -t.getTimezoneOffset()/60;
              if(timeZone == 9){
                var str =this.getLocalTime(9).toString();
                var strs = str.split(' ');
                var newStr = strs[0]+', '+strs[2]+' '+strs[1]+' '+strs[3] +' '+ strs[4]+' JST';
                this.cur_date = newStr;
              }else{

                this.cur_date = t.toUTCString();
              }

            },
            initFBX(){
              var self = this;
              var loader = new FBXLoader();
              loader.load(this.model_url,(object)=>{
                object.children.forEach(element => {
                if(element.children.length > 0)
                {
                    element.children.forEach(el=>{
                        
                        if(el.name !=="earth"&& el.material){
                            if(el.material.length > 0 )
                            {
                                el.material.forEach(elm=>{
                             
                                    elm.transparent = true;
                                    elm.side = 2;
                                    elm.depthWrite = false;
                                });
                            }else{

                            el.material.transparent = true;
                            el.material.side = 2;
                            el.material.depthWrite = false;
                            }
                            
                        }else if(el.name =="earth" && el.material)
                        {
                            el.material.map = new THREE.TextureLoader().load("../res/model/earth.png");
                        }
                    });
                }else{
                    if(element.name !=="earth" && element.material){

                        element.material.transparent = true;
                        element.material.side = 2;
                        element.material.depthWrite = false;
                    }
                }
               
            });
            object.scale.setScalar(0.1);
            object.position.set(0,0,0);
            if (object.animations.length > 0) {
                object.mixer = new THREE.AnimationMixer(object);
                self.mixer = object.mixer;
               
                self.action = object.mixer.clipAction(object.animations[0]);
                self.action.setLoop(THREE.LoopOnce);
            }
                  self.scene.add( object );
              },null,(err)=>{
                        console.log(err)
              })
            },
               initFBXAR(){
              var self = this;
              var loader = new FBXLoader();
              var colorNum = 0;
              if((self.watageColor >=0 && self.watageColor<=29) || (self.watageColor>=330&& self.watageColor<=359)){
                    colorNum=0;
              }else if(self.watageColor >=30 && self.watageColor<=89)
              {
                colorNum = 1;
              }else if(self.watageColor >= 90 && self.watageColor <= 149){

                colorNum = 2;
              }else if(self.watageColor >= 150 && self.watageColor <= 209){

                colorNum = 3;
              }else if(self.watageColor >= 210 && self.watageColor <= 269){

                colorNum = 4;
              }else if(self.watageColor >= 270 && self.watageColor <= 329){

                colorNum = 5;
              }
              var url = "../res/model/dande_ar_"+(self.watageShape==0?'A':self.watageShape==1?'B':self.watageShape==2?'C':self.watageShape==3?'D':'E')+colorNum+".fbx";
              loader.load(url,(object)=>{
                object.children.forEach(element => {
                if(element.children.length > 0)
                {
                    element.children.forEach(el=>{
                        
                        if(el.name !=="earth"&& el.material){
                            if(el.material.length > 0 )
                            {
                                el.material.forEach(elm=>{
                             
                                    elm.transparent = true;
                                    elm.side = 2;
                                    elm.depthWrite = false;
                                });
                            }else{

                            el.material.transparent = true;
                            el.material.side = 2;
                            el.material.depthWrite = false;
                            }
                            
                        }
                    });
                }else{
                    if(element.name !=="earth" && element.material){

                        element.material.transparent = true;
                        element.material.side = 2;
                        element.material.depthWrite = false;
                    }
                }
               
            });
            object.scale.setScalar(3);
            object.position.set(0,0,0);
            object.rotation.set(90,0,0);
            if (object.animations.length > 0) {
                object.mixer = new THREE.AnimationMixer(object);
                self.mixerAR = object.mixer;
               
                self.actionAR = object.mixer.clipAction(object.animations[0]);
                self.actionAR.play();
            }
                  self.sceneAR.add( object );
              },null,(err)=>{
                        console.log(err)
              })
            }
    }
  }
</script>



<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  #main{
    /* position:fixed; */
    width: 100%;
    margin: auto;
    background-color: #f8f7f2;
    /* height: 100%; */
    /* top: 0%; */
    /* left: 0%; */
  }
 
  .full-div{
    position:fixed;
    width: 100%;
    height: 100%;
    top: 0%;
    left: 0%;
  }
  .margin-top-10{
    margin-top: 15vw;
  }
  .main-title{
    
    text-align: center;
    margin: auto;
  }
  .main-word{
    word-wrap:break-word;
    text-align: center;
    margin: auto;
    white-space: pre-wrap;
  }
  .fade-in-ani{
    opacity: 0;
    animation:fade-in 1.5s linear 0s 1 normal forwards;
  }
  @keyframes fade-in {
    0% {opacity: 0;}/*初始状态 透明度为0*/
    100% {opacity: 1;}/*结束状态 透明度为1*/
  }
  @-webkit-keyframes fade-in {/*针对webkit内核*/
    0% {opacity: 0;}/*初始状态 透明度为0*/
    100% {opacity: 1;}/*结束状态 透明度为1*/
  }
  .fade-in-ani-delay{
    opacity: 0;
    animation:fade-in-delay 1s linear 1.5s 1 normal forwards;
  }
  @keyframes fade-in-delay {
    0% {opacity: 0;}/*初始状态 透明度为0*/
    100% {opacity: 1;}/*结束状态 透明度为1*/
  }
  @-webkit-keyframes fade-in-delay {/*针对webkit内核*/
    0% {opacity: 0;}/*初始状态 透明度为0*/
    100% {opacity: 1;}/*结束状态 透明度为1*/
  }
  .fix-bottom{
    width: 100%;
    /* height: 100%; */
    position: fixed;
    top: 100%;
    left: 50%;
    transform: translate(-50%,-100%);
  }
  .fix-center{
    width: 100%;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
  .flex-div{
    display: inline-flex;
    justify-content: space-around;
  }
  .div-QR{
    width: 70%;
    margin: auto;
  }
  .div-web{
    width: 100%;
    background-color: #a8a8a8;
    text-align: center;
    margin: auto;
    border: none;
    /* height: 15vw; */
    line-height: 15vw;
  }
  .hide{
    display: none;
  }
  .input-name{
    display: block;
    margin-left: 5%;
  }
  .input-div{
    display: inline-block;
    margin: auto;
    text-align: left;
    width: 40%;

  }
  .input-frame{
    position: relative;
    width: 100%;

  }
   .input-frame img{
    width: 100%;

  }
  .form-input{
      position: absolute;
      top:41%;
      left: 50%;
      height: 60%;
      transform: translate(-50%,-50%);
      outline:0;
      width: 80%;
      font-size: 6vw;
      line-height: 10vw;
      text-align: left;
    background-color: #ffffff;
    border: none;
  }
  .pos-relative{

    position: relative;
  }
  .pos-absolute{

    position: absolute;
    top:50%;
    left:50%;
        transform: translate(-50%,-50%);
  }
  .pos-inputs{
    position: absolute;
    top:45%;
  }
  .input-next{
    position: relative;
    margin: auto;
    width: 60%;
  }
  .input-next img{
    width: 100%;
  }
  .input-next span{ 
    position: absolute;
    top: 41%;
    font-size: 6vw;  
    left: 48%;
    transform: translate(-50%,-50%);
    pointer-events: none;
  }
  .input-btn span{

    pointer-events: none;
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
  }
  .input-btn{
    margin: auto;
    
  }
  .input-btn :active{
    position: relative;
    top: 1px;
    transform: scale(0.9);
    -webkit-transform: scale(0.9);
  }
  
  .line{
    position: absolute;
    top: 0%;
    left: 50%;
    transform: translate(-50%,0%);
    width: 2px;
    animation: moveto 2s linear 0s infinite normal forwards;
  }
  @keyframes moveto {
    0% {height: 0px;top: 8vw;}
    50% {height: 6vw;top: 8vw;}
    100% {height: 0px;top:13vw;}
  }
  @-webkit-keyframes moveto{/*针对webkit内核*/
    0% {height: 0px;top: 8vw;}
    50% {height: 6vw;top:8vw;}
    100% {height: 0px;top:13vw;}
  }
  .icon-div{
    display: inline-flex;
    width: 100%;
    justify-content: space-between;

  }
   .icon-div a{
     padding: 3vw;
  }
  .icon-div img{
    width:30px;
  }
   .top-right{
     position: absolute;
     top:-75%;
     right:0%;
    display: inline-flex;
    z-index: 2;
    /*width: 7%;*/
  }
  .label{
    cursor: pointer;
    padding-left: 5vw;
    
  }
  .activeStyle{
    opacity: 0.5;
  }
  .font-float{
    animation: lineto 0.5s ease-in-out 0s 1 normal forwards;
  }
  @keyframes lineto {
    0% {line-height: 9vw;opacity: 0.5;}
    100% {line-height: 7vw;opacity: 1;}
  }
  @-webkit-keyframes lineto{/*针对webkit内核*/
   0% {line-height: 9vw;opacity: 0.5;}
    100% {line-height: 7vw;opacity: 1;}
  }
    .float-title{
    animation: lineto2 1s ease-in-out 0s 1 normal forwards;
  }
  @keyframes lineto2 {
    0% {line-height: 11vw;opacity: 0.5;}
    100% {line-height: 9vw;opacity: 1;}
  }
  @-webkit-keyframes lineto2{/*针对webkit内核*/
   0% {line-height: 11vw;opacity: 0.5;}
    100% {line-height: 9vw;opacity: 1;}
  }
  .show-opacity{
      animation: opacity1 1s ease-in-out 0s 1 normal forwards;
  }
  @keyframes opacity1 {
    0% {opacity: 0;}
    100% {opacity: 1;}
  }
  @-webkit-keyframes opacity1{/*针对webkit内核*/
    0% {opacity: 0;}
    100% {opacity: 1;}
  }
   .hide-opacity{
      animation: opacity2 1s ease-in-out 0s 1 normal forwards;
  }
  @keyframes opacity2 {
    0% {opacity: 1;}
    100% {opacity: 0;}
  }
  @-webkit-keyframes opacity2{/*针对webkit内核*/
    0% {opacity: 1;}
    100% {opacity: 0;}
  }
  .moveToCenter canvas
  {
    transform: translate(-25%,-50%);
    /* transition-property: all;
    transition-duration: 1s; */
    position: absolute;
    top: 50%;
    left: 50%;
    width: 200%;
    
  }
    .share-view{

        width: 100%;
        margin: auto;
        position: absolute;
        transform: translate(-50%,-50%);
        top: 50%;
        left:50%
    }
   
     .pop{

        background: rgba(0, 0, 0, 0.90);
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 2;

        overflow: auto;

    }
    .pop2 {

        background: rgba(0, 0, 0, 0.50);
    
    }
    .dialog-view{
      position: absolute;
      top: 50%;
      left: 50%;
      background-color: #f8f7f2;
      margin: auto;
      text-align: center;
      color: red;
      width: 350px;
      height: 140px;
      /* line-height:50px; */
      transform: translate(-50%,-50%);
      border-radius: 10px;
      opacity: 1;
    white-space: pre-wrap;
    }
    .dialog-view-span-en{
      display: block;
      margin: 0.8rem;
    }
      .dialog-view-span-jp{
      display: block;
      margin:0.8rem;
      
    }
    .loading-view{
      pointer-events: none;
      /* opacity: 0.6; */
       /* position: absolute; */
      /* background-color: #f8f7f2; */
      width: 100%;
      height: 100%;
      /* top: 50%;
      left: 50%; */
      margin: auto;
      text-align: center;
      /* transform: translate(-50%,-50%); */
    }
    .loading-view img{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      opacity: 1;
      width: 10%;
    }
    .btn-close{
      display: block;
      color: black;
      cursor: pointer;
      width: 50%;
      margin: auto;
      font-size: 4vw;
    }
    .earth_view{
      z-index: -1;
    }
    .icon-div-login{
      width: 25%;
      margin: auto;
    }
    .icon-div-login a{

      display: block;
    }
    .icon-div-login img{
      width: 100%;
      margin-top: 1.5rem;
      max-width: 250px;
    }
    .final-logo{
      position: absolute;
      top: 30px;
      left: 50%;
      transform: translate(-50%,0%);
      width: 90%;
    }
     .final-time{
       width: 100%;
       text-align: left;
       display: block;
      font-size: 8vw;
      color: #d3d2cd;
    }
     .final-name{
       width: 100%;
      color: #f8f7f2;
       display: block;
      font-size: 12vw;
       text-align: left;
    }
.scroll{
  position: fixed;
  top: 55%;
  left: 50%;
  transform: translate(-50%,-50%);
}
.scroll-top{
  animation: topto 1.5s ease-in-out 0s 1 normal forwards;
}
  @keyframes topto {
    0% {top: 55%;}
    100% {top: 20%;}
  }
  @-webkit-keyframes topto{/*针对webkit内核*/
    0% {top: 55%;}
    100% {top: 20%;}
  }
.bg-color-yellow{

    background-color: rgb(246, 218, 100);
}
.input-frame .wrong{
  position: absolute;
  width: 93%;
  top: 41%;
  left: 48%;
  transform: translate(-50%,-50%);

}
.waitLogo{
    position: absolute;
  width: 70%;
  top: 90%;
  left: 50%;
  transform: translate(-50%,-100%);

}
.down-flower{
  position: absolute;
  top: 0%;
  left: 50%;
  transform: translate(-50%,0%);
}
.w-font{
      color: #ffffff;
      text-shadow:0px 0px 0.5rem rgb(0, 0, 0),0px 0px 1rem rgba(0, 0, 0, 1),0px 0px 1.8rem rgba(0, 0, 0, 1);
}
.b-font{

      color: #000;
      text-shadow:0px 0px 0.5rem rgb(255, 255, 255),0px 0px 1rem rgb(255, 255, 255),0px 0px 1.8rem rgb(255, 255, 255);
}
.l-font{
  font-size: 90%;
}
.s-font{
  font-size: 130%;
}
.radio-div{
  display:  inline-block;
  margin: 5px;
}
input[type=radio]:after{
  color: black;
}

  .lg-div
  {
    position: relative;
    width: 110px;
  }
.lg-div input {
    opacity:0;
    position:absolute;
    left:-99999px;
  }

  .lg-div input:checked + label {
    order: 1;
    z-index:2;
    background: #f8f7f2;
    border-top:black solid 1px;
    position:relative;

  }
.zq-drop-list-new{
    display: inline-block;
    width: 100%;
    position: relative;
    border: solid 2px black;
    box-sizing: border-box;
    z-index: 10;
  }
  .zq-drop-list-new span{
    display: block;
    height: 1.8rem;
    line-height: 1.8rem;
    /*font-size: 14px;*/
    text-align: center;
    /* background-size: 1rem ; */
    background-color: #f8f7f2;
  }
  .zq-drop-list-new ul{
    background: #f8f7f2;
    position: absolute;
    top: 1.8rem;
    left:-2px;
    width: 100%;
    margin: 0;
    padding: 0;
    /*box-sizing: border-box;*/
    border: solid 2px rgb(138, 136, 136);
    border-top: solid 2px rgb(0, 0, 0);
    /*border: solid 1px #f1f1f1;*/
    /*border-radius: 4px;*/
    overflow: hidden;
  }
  .zq-drop-list-new li{
    text-align: center;
    list-style: none;
    height: 1.8rem;
    line-height: 1.8rem;
    background: #f8f7f2;
  }
  .zq-drop-list-new li:hover{
    background: #d8d7d7;
  }
  .zq-drop-list-new .chossLi{
    background: #d8d7d7;
  }
  .triangle-down {
    position: absolute;
    top:35%;
    right: 8%;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 10px solid rgb(138, 136, 136);
}
.ar-gif{
  width:100%;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
}
/deep/ .video-js .vjs-control-bar button{
  outline: none;

}
.icp-a{
  text-decoration-line: none;
  color:rgb(70, 70, 70);
}
</style>
