export default {
    fr: {
        content: {
            icp: {
                company: '© 2021 上海丽昂数字科技有限公司',
                url: 'http://beian.miit.gov.cn',
                link: '沪 ICP 备 19003696 号-1',
                url2: 'http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31010402009473',
                link2: '沪公网安备 31010402009473号',
            },
            scroll: "Faire defiler jusqu’en bas",
            fname: "PRÉNOM",
            lname: "NOM",
            content: "Le <i>DANDELION</i> est une oeuvre d’art connectée. Envoyez vos Voeux au travers d’une aigrette et fleurissez le monde de vos voeux et prières.",
            content2: "CRÉEZ VOTRE AIGRETTE DE DANDELION PERSONNALISÉE.",
            content3: "Voici votre aigrette de DANDELION personnalisée et unique",
            content4: "- EXPÉRIENCE SUR PLACE –\nGénérez le QR Code à scanner.",
            sendPrayer: "ENVOYER VOS VOEUX",
            watege1: "Votre aigrette de DANDELION s’est envolé de ",
            watege2: " pour aller fleurir à ",
            watege3: " Merci de votre participation.",
            watege4: "Votre aigrette de DANDELION s’est envolé de ",
            watege5: " Merci de votre participation.",
            webJoin: "- EXPÉRIENCE EN LIGNE -",
            webJoinAR: "綿毛を飛ばす",
            tipsAR: "枠の中に、ワインラベルの綿毛を合わせて下さい。",
            nextAR: "NEXT",
            nameWraning: "\nVeuillez utiliser moins de 10 characters en format demi-chasse.",
            ngWraning: 'Contient un message qui ne peut pas être utilisé.\nVeuillez corriger les informations que vous avez saisies et réessayer.',
            loginTitle1: "Suivez-nous sur les réseaux Sociaux",
            loginTitle2: "Ensuite Revenez à l’expérience DANDELION",
            loginBtn: "COMMENCER",
            btnClose: "ok",


            siteInfo: {
                "default": {
                    id: "",
                    name: "",
                    qrDisplay: false,
                    accountDisplay: true,
                    img: "./res/texture/waitBg/watage_bg_basic.jpg",
                    logo: "./res/texture/waitBg/watage_logo_basic.png"
                },
                "s00101": {
                    id: "s00101",
                    name: "Saga",
                    qrDisplay: true,
                    accountDisplay: false,
                    img: "./res/texture/waitBg/watage_bg_saga.jpg",
                    logo: "./res/texture/waitBg/watage_logo_basic.png"
                },
                "s00102": {
                    id: "s00102",
                    name: "Saga",
                    qrDisplay: false,
                    accountDisplay: false,
                    img: "./res/texture/waitBg/watage_bg_saga.jpg",
                    logo: "./res/texture/waitBg/watage_logo_basic.png"
                },
                "s00103": {
                    id: "s00103",
                    name: "Nihonchinbotsu",
                    qrDisplay: true,
                    accountDisplay: false,
                    img: "./res/texture/waitBg/watage_bg_nihonchinbotsu.jpg",
                    logo: "./res/texture/waitBg/watage_logo_nihonchinbotsu.png"
                },
                "s00104": {
                    id: "s00104",
                    name: "TokyoTower",
                    qrDisplay: true,
                    accountDisplay: true,
                    img: "./res/texture/waitBg/watage_bg_tokyotower.jpg",
                    logo: "./res/texture/waitBg/watage_logo_basic.png"
                },
                "s00105": {
                    id: "s00105",
                    name: "Hikone Castle",
                    qrDisplay: true,
                    accountDisplay: true,
                    img: "./res/texture/waitBg/watage_bg_basic.jpg",
                    logo: "./res/texture/waitBg/watage_logo_basic.png"
                },
                "s00106": {
                    id: "s00106",
                    name: "Shurijo Castle",
                    qrDisplay: true,
                    accountDisplay: true,
                    img: "./res/texture/waitBg/watage_bg_basic.jpg",
                    logo: "./res/texture/waitBg/watage_logo_basic.png"
                },
                "s00107": {
                    id: "s00107",
                    name: "",
                    qrDisplay: true,
                    accountDisplay: false,
                    img: "./res/texture/waitBg/watage_bg_basic.jpg",
                    logo: "./res/texture/waitBg/watage_logo_basic.png"
                },
                "s00108": {
                    id: "s00108",
                    name: "",
                    qrDisplay: false,
                    accountDisplay: true,
                    img: "./res/texture/waitBg/watage_bg_basic.jpg",
                    logo: "./res/texture/waitBg/watage_logo_basic.png"
                },
                "s00109": {
                    id: "s00109",
                    name: "",
                    qrDisplay: true,
                    accountDisplay: true,
                    img: "./res/texture/waitBg/watage_bg_basic.jpg",
                    logo: "./res/texture/waitBg/watage_logo_basic.png"
                },
                "s00110": {
                    id: "s00110",
                    name: "",
                    qrDisplay: true,
                    accountDisplay: true,
                    img: "./res/texture/waitBg/watage_bg_basic.jpg",
                    logo: "./res/texture/waitBg/watage_logo_basic.png"
                },
                "s00111": {
                    id: "s00111",
                    name: "",
                    qrDisplay: true,
                    accountDisplay: true,
                    img: "./res/texture/waitBg/watage_bg_paris.png",
                    logo: ""
                },
                "s00112": {
                    id: "s00112",
                    name: "",
                    qrDisplay: true,
                    accountDisplay: true,
                    img: "./res/texture/waitBg/watage_bg_webar.jpg",
                    logo: ""
                },
                "s00113": {
                    id: "s00113",
                    name: "",
                    qrDisplay: true,
                    accountDisplay: true,
                    img: "./res/texture/waitBg/watage_bg_basic.jpg",
                    logo: "./res/texture/waitBg/watage_logo_basic.png"
                },
                "s00114": {
                    id: "s00114",
                    name: "",
                    qrDisplay: true,
                    accountDisplay: true,
                    img: "./res/texture/waitBg/watage_bg_basic.jpg",
                    logo: "./res/texture/waitBg/watage_logo_basic.png"
                },
                "s00115": {
                    id: "s00115",
                    name: "",
                    qrDisplay: true,
                    accountDisplay: true,
                    img: "./res/texture/waitBg/watage_bg_basic.jpg",
                    logo: "./res/texture/waitBg/watage_logo_basic.png"
                },
                "s00116": {
                    id: "s00116",
                    name: "",
                    qrDisplay: true,
                    accountDisplay: true,
                    img: "./res/texture/waitBg/watage_bg_basic.jpg",
                    logo: "./res/texture/waitBg/watage_logo_basic.png"
                },
                "s00117": {
                    id: "s00117",
                    name: "",
                    qrDisplay: true,
                    accountDisplay: true,
                    img: "./res/texture/waitBg/watage_bg_basic.jpg",
                    logo: "./res/texture/waitBg/watage_logo_basic.png"
                },
                "s00118": {
                    id: "s00118",
                    name: "",
                    qrDisplay: true,
                    accountDisplay: true,
                    img: "./res/texture/waitBg/watage_bg_basic.jpg",
                    logo: "./res/texture/waitBg/watage_logo_basic.png"
                },
                "s00119": {
                    id: "s00119",
                    name: "",
                    qrDisplay: true,
                    accountDisplay: true,
                    img: "./res/texture/waitBg/watage_bg_basic.jpg",
                    logo: "./res/texture/waitBg/watage_logo_basic.png"
                },
                "s00120": {
                    id: "s00120",
                    name: "",
                    qrDisplay: true,
                    accountDisplay: true,
                    img: "./res/texture/waitBg/watage_bg_basic.jpg",
                    logo: "./res/texture/waitBg/watage_logo_basic.png"
                },
                "s00121": {
                    id: "s00121",
                    name: "",
                    qrDisplay: true,
                    accountDisplay: true,
                    img: "./res/texture/waitBg/watage_bg_basic.jpg",
                    logo: "./res/texture/waitBg/watage_logo_basic.png"
                },
            }
        }


    }
}