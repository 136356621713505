export default {
    en: {
        content: {
            icp: {
                company: '© 2021 上海丽昂数字科技有限公司',
                url: 'http://beian.miit.gov.cn',
                link: '沪 ICP 备 19003696 号-1',
                url2: 'http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31010402009473',
                link2: '沪公网安备 31010402009473号',
            },
            scroll: "scroll",
            fname: "FIRST NAME",
            lname: "LAST NAME",
            content: "<i>DANDELION</i> IS AN ART THAT CONNECTS THE WORLD. SEND YOUR PRAYER FOR PEACE ON A DANDELION SEED, AND LET YOUR FLOWER BLOOM IN THE WORLD.",
            content2: "CREAT YOUR ONE AND ONLY DANDELION SEED",
            content3: "MEET YOUR OWN  DANDELION SEED. NO OTHER SEED HAS THE SAME SHAPE AND COLOR.",
            content4: "- EXPERIENCE ONSITE –\nSCAN THIS SCREEN UPON THE QR CODE READER",
            sendPrayer: "SEND A PRAYER",
            watege1: "YOUR DANDELION SEED FLOATED FROM ",
            watege2: " TO ",
            watege3: " AND BECAME A FLOWER.",
            watege4: "YOUR DANDELION SEED FLOATED TO ",
            watege5: " AND BECAME A FLOWER.",
            webJoin: "- EXPERIENCE ONLINE -",
            webJoinAR: "綿毛を飛ばす",
            tipsAR: "枠の中に、ワインラベルの綿毛を合わせて下さい。",
            nextAR: "NEXT",
            nameWraning: "\nPLEASE ENTER NO MORE THAN 10 CHARACTERS IN ENGLISH",
            ngWraning: 'Contains unusable messages.\nPlease correct the information you entered and try again.',
            loginTitle1: "FOLLOW US ON SOCIAL MEDIA!",
            loginTitle2: "THEN COME BACK TO\nEXPERIENCE DANDELION.",
            loginBtn: "START",
            btnClose: "ok",



            siteInfo: {
                "default": {
                    id: "",
                    name: "",
                    qrDisplay: false,
                    accountDisplay: true,
                    img: "./res/texture/waitBg/watage_bg_basic.jpg",
                    logo: "./res/texture/waitBg/watage_logo_basic.png"
                },
                "s00101": {
                    id: "s00101",
                    name: "Saga",
                    qrDisplay: true,
                    accountDisplay: false,
                    img: "./res/texture/waitBg/watage_bg_saga.jpg",
                    logo: "./res/texture/waitBg/watage_logo_basic.png"
                },
                "s00102": {
                    id: "s00102",
                    name: "Saga",
                    qrDisplay: false,
                    accountDisplay: false,
                    img: "./res/texture/waitBg/watage_bg_saga.jpg",
                    logo: "./res/texture/waitBg/watage_logo_basic.png"
                },
                "s00103": {
                    id: "s00103",
                    name: "Nihonchinbotsu",
                    qrDisplay: true,
                    accountDisplay: false,
                    img: "./res/texture/waitBg/watage_bg_nihonchinbotsu.jpg",
                    logo: "./res/texture/waitBg/watage_logo_nihonchinbotsu.png"
                },
                "s00104": {
                    id: "s00104",
                    name: "TokyoTower",
                    qrDisplay: true,
                    accountDisplay: true,
                    img: "./res/texture/waitBg/watage_bg_tokyotower.jpg",
                    logo: "./res/texture/waitBg/watage_logo_basic.png"
                },
                "s00105": {
                    id: "s00105",
                    name: "Hikone Castle",
                    qrDisplay: true,
                    accountDisplay: true,
                    img: "./res/texture/waitBg/watage_bg_basic.jpg",
                    logo: "./res/texture/waitBg/watage_logo_basic.png"
                },
                "s00106": {
                    id: "s00106",
                    name: "Shurijo Castle",
                    qrDisplay: true,
                    accountDisplay: true,
                    img: "./res/texture/waitBg/watage_bg_basic.jpg",
                    logo: "./res/texture/waitBg/watage_logo_basic.png"
                },
                "s00107": {
                    id: "s00107",
                    name: "",
                    qrDisplay: true,
                    accountDisplay: false,
                    img: "./res/texture/waitBg/watage_bg_basic.jpg",
                    logo: "./res/texture/waitBg/watage_logo_basic.png"
                },
                "s00108": {
                    id: "s00108",
                    name: "",
                    qrDisplay: false,
                    accountDisplay: true,
                    img: "./res/texture/waitBg/watage_bg_basic.jpg",
                    logo: "./res/texture/waitBg/watage_logo_basic.png"
                },
                "s00109": {
                    id: "s00109",
                    name: "高知城",
                    qrDisplay: true,
                    accountDisplay: true,
                    img: "./res/texture/waitBg/watage_bg_kouchijo.jpg",
                    logo: ""
                },
                "s00110": {
                    id: "s00110",
                    name: "",
                    qrDisplay: true,
                    accountDisplay: true,
                    img: "./res/texture/waitBg/watage_bg_kumatojo.jpg",
                    logo: ""
                },
                "s00111": {
                    id: "s00111",
                    name: "",
                    qrDisplay: true,
                    accountDisplay: true,
                    img: "./res/texture/waitBg/watage_bg_paris.png",
                    logo: ""
                },
                "s00112": {
                    id: "s00112",
                    name: "",
                    qrDisplay: true,
                    accountDisplay: true,
                    img: "./res/texture/waitBg/watage_bg_webar.jpg",
                    logo: ""
                },
                "s00113": {
                    id: "s00113",
                    name: "",
                    qrDisplay: true,
                    accountDisplay: true,
                    img: "./res/texture/waitBg/watage_bg_basic.jpg",
                    logo: "./res/texture/waitBg/watage_logo_basic.png"
                },
                "s00114": {
                    id: "s00114",
                    name: "",
                    qrDisplay: true,
                    accountDisplay: true,
                    img: "./res/texture/waitBg/watage_bg_basic.jpg",
                    logo: "./res/texture/waitBg/watage_logo_basic.png"
                },
                "s00115": {
                    id: "s00115",
                    name: "",
                    qrDisplay: true,
                    accountDisplay: true,
                    img: "./res/texture/waitBg/watage_bg_basic.jpg",
                    logo: "./res/texture/waitBg/watage_logo_basic.png"
                },
                "s00116": {
                    id: "s00116",
                    name: "",
                    qrDisplay: true,
                    accountDisplay: true,
                    img: "./res/texture/waitBg/watage_bg_basic.jpg",
                    logo: "./res/texture/waitBg/watage_logo_basic.png"
                },
                "s00117": {
                    id: "s00117",
                    name: "",
                    qrDisplay: true,
                    accountDisplay: true,
                    img: "./res/texture/waitBg/watage_bg_basic.jpg",
                    logo: "./res/texture/waitBg/watage_logo_basic.png"
                },
                "s00118": {
                    id: "s00118",
                    name: "",
                    qrDisplay: true,
                    accountDisplay: true,
                    img: "./res/texture/waitBg/watage_bg_basic.jpg",
                    logo: "./res/texture/waitBg/watage_logo_basic.png"
                },
                "s00119": {
                    id: "s00119",
                    name: "",
                    qrDisplay: true,
                    accountDisplay: true,
                    img: "./res/texture/waitBg/watage_bg_basic.jpg",
                    logo: "./res/texture/waitBg/watage_logo_basic.png"
                },
                "s00120": {
                    id: "s00120",
                    name: "",
                    qrDisplay: true,
                    accountDisplay: true,
                    img: "./res/texture/waitBg/watage_bg_basic.jpg",
                    logo: "./res/texture/waitBg/watage_logo_basic.png"
                },
                "s00121": {
                    id: "s00121",
                    name: "",
                    qrDisplay: true,
                    accountDisplay: true,
                    img: "./res/texture/waitBg/watage_bg_basic.jpg",
                    logo: "./res/texture/waitBg/watage_logo_basic.png"
                },
            }
        }


    }
}