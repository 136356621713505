<template>
  <div id="app">
    <Main/>
  </div>
</template>

<script>
import Main from './components/Main.vue'

export default {
  name: 'App',
  components: {
    Main
  },
}
</script>

<style>
  @font-face {
        font-family: 'cnFont';
        src: url("common/font/SIMHEI.TTF");
    }
    body, h1, h2, h3, h4, h5, h6, hr, p, blockquote, /* structural elements 结构元素 */
    dl, dt, dd, ul, ol, li, /* list elements 列表元素 */
    pre, /* text formatting elements 文本格式元素 */
    form, fieldset, legend, button, input, textarea, /* form elements 表单元素 */
    th, td /* table elements 表格元素 */
    {
        margin: 0;
        padding: 0;
    }
  html{

    /* background: black; */
    background: #f8f7f2;

  }
  body{
    margin: 0px;
    /*background: black;*/
    /*margin-top: 60px;*/
  }
  
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: rgb(70, 70, 70);
  padding: 0px 0%;
  user-select: none;
  
  
}
img{
  /* pointer-events: none; */
}
.font-Regular-cn{
    font-family: 'cnFont';
    font-weight: 400;
    font-style: normal;
}
.font-Bold-cn{
    font-family: 'cnFont';
    font-weight: 700;
    font-style: normal;
}
.font-Medium-cn{
    font-family: 'cnFont';
    font-weight: 500;
    font-style: normal;
}
  .font-Regular-jp{

    font-family:'Noto Sans Japanese', sans-serif;
    font-weight: 400;
    font-style: normal;
  }
  .font-Medium-jp{

    font-family:'Noto Sans Japanese', sans-serif;
    font-weight: 500;
    font-style: normal;
  }
  .font-Bold-jp{

    font-family:'Noto Sans Japanese', sans-serif;
    font-weight: 700;
    font-style: normal;
  }
  .font-Regular-en{

    font-family:"sofia-pro", sans-serif;
    font-weight: 400;
    font-style: normal;
  }
  .font-Bold-Italic-en{

    font-family:"sofia-pro", sans-serif;
    font-weight: 500;
    font-style: normal;
    font-style: italic;
  }
  .font-Bold-en{

    font-family:"sofia-pro", sans-serif;
    font-weight: 700;
    font-style: normal;
  }
  .font-deca{
    font-family: 'Lexend Deca', sans-serif;
    width: 100%;
    text-align: center;
    font-size: 2em;

  }
  .font-size-31{
    font-size: 9vw;
  }
   .font-size-18{
    font-size: 5vw;
  }
  .font-size-13{
    font-size: 1rem;
  }
  .font-size-14{
    font-size: 4vw;
  }
  .font-size-24{
    font-size: 7vw;
    
  }

  .font-size-3{
    font-size: 3.8vw;
    
  }
.width-normal{
    width: 90%;
    margin: auto;
}
.width-full{
    width: 100%;
}
.margin-center{
    margin: auto;
}
.div-disactive{
  display: none;
}
.div-active{
  display:inline-block;
}
</style>
